import React from "react";

import { cn, parseUrl } from "@/lib/utils";

import { AvatarV2, Icon, Popover } from "@/app/components";
import { ChipV2 } from "@/app/components/v2/chip-v2.tsx";
import { Fund } from "@/app/screens/add-company/company-signals-step/company-signals-form.ts";
import { RecommendedFundData } from "@/app/screens/add-company/company-signals-step/use-recommended-fund.ts";

const fundOptions: Array<{ value: Fund; label: string }> = [
  { value: Fund.LocalGlobe, label: "LocalGlobe" },
  { value: Fund.Latitude, label: "Latitude" },
  { value: Fund.Solar, label: "Solar" },
  { value: Fund.Pipeline, label: "No. Move straight to Pipeline" },
];

type FundSelectControlProps = {
  value?: Fund;
  onChange: (value: Fund) => void;
  recommended?: RecommendedFundData;
};

const LatestRoundDetails = ({ details }: Pick<RecommendedFundData, "details">) => {
  const dealroomCompany = details;
  const latestRound = dealroomCompany?.fundingRounds?.nodes[0];

  const imageUrl = parseUrl(dealroomCompany?.image);
  return (
    <div className="space-y-1">
      <div className="flex items-center gap-1 text-sm font-semibold">
        {imageUrl && <AvatarV2 size="xs" src={imageUrl} type="company" />}
        <span>{dealroomCompany?.name}</span>
      </div>
      {dealroomCompany?.totalFunding != null && (
        <div className="text-xs">
          <span>Total funding: </span>
          <span className="text-green-600">$</span>
          <span>{dealroomCompany?.totalFunding}m</span>
        </div>
      )}
      <div className="text-xs">Last round raised: {latestRound?.round}</div>
    </div>
  );
};

export const FundSelectControl = ({ value, onChange, recommended }: FundSelectControlProps) => {
  return (
    <div className="@container">
      <ul className="grid select-none grid-cols-1 gap-2 @xl:grid-cols-4 @xl:gap-4">
        {fundOptions.map((option) => (
          <li
            key={option.value}
            onClick={() => {
              onChange(option.value);
            }}
            className={cn(
              "overflow-hidden rounded-md border border-gray-400 @xl:aspect-square",
              "transition-colors duration-500 ease-in",
              option.value === value && "border-green-600",
            )}
          >
            <div className="flex size-full cursor-pointer items-center gap-2 p-3 transition-colors duration-200 ease-in hover:bg-gray-100 @xl:flex-col @xl:justify-between @xl:gap-1 @xl:p-4">
              <Icon
                type="Check"
                className={cn(
                  "invisible size-4 shrink-0 text-green-600 opacity-0 transition-opacity duration-500 @xl:mt-auto @xl:size-6",
                  option.value === value && "visible opacity-100",
                )}
              />
              <span className="mb-auto inline-block text-center font-medium">{option.label}</span>
              <div
                className={cn(
                  "invisible ml-auto font-normal @xl:ml-0",
                  !!recommended && option.value === recommended.fund && "visible",
                )}
              >
                {!!recommended?.details && (
                  <Popover placement="bottom" childrenContent={<LatestRoundDetails details={recommended?.details} />}>
                    <ChipV2 color="green" label="Suggested" className="font-normal" />
                  </Popover>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
