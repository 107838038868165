import { ApolloClient, ApolloLink, from, fromPromise, HttpLink, InMemoryCache } from "@apollo/client";
import { ErrorResponse, onError } from "@apollo/client/link/error";

import { globalErrorToast } from "@/app/misc/errorToast.ts";
import { refreshTokenCall } from "@/app/screens/login/refreshToken.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

import { captureGraphqlErrors } from "./sentry";

export function initApollo() {
  const httpLink = new HttpLink({
    uri: `${import.meta.env.VITE_NAZARE_API_DOMAIN}/graphql`,
  });

  const authLink = new ApolloLink((operation, forward) => {
    const authorization = authStore.accessToken ? `Bearer ${authStore.accessToken}` : undefined;

    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization,
      },
    }));

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
    // try refreshing token on 401 and repeat the failed request
    if (networkError && "statusCode" in networkError && networkError.statusCode === 401) {
      return fromPromise(
        refreshTokenCall().catch(() => {
          authStore.logout();
          return;
        }),
      )
        .filter((value) => Boolean(value))
        .flatMap((accessToken) => {
          const oldHeaders = operation.getContext().headers;

          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${accessToken}`,
            },
          });
          return forward(operation);
        });
    }

    captureGraphqlErrors({ graphQLErrors, operation, networkError });
    globalErrorToast({ graphQLErrors });
  });

  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            lgCompanies: {
              keyArgs: ["filter", "orderBy"],
              merge(
                existing = {
                  edges: [],
                },
                incoming,
                options,
              ) {
                const {
                  args: { after, offset },
                  readField,
                } = options;

                if (offset >= 0) {
                  return {
                    ...incoming,
                    edges: [...(existing?.edges || []), ...(incoming?.edges || [])],
                  };
                }

                const existingItems = existing ? existing.edges.slice(0) : [];
                const incomingItems = incoming?.edges || [];

                let next = offsetFromCursor(existingItems, after, readField);
                // If we couldn't find the cursor, default to appending to
                // the end of the list, so we don't lose any data.
                if (next < 0) {
                  next = existingItems.length;
                }

                // this prevents from adding duplicates
                const merged = [...existingItems.slice(0, next), ...incomingItems];

                return {
                  ...incoming,
                  edges: merged,
                };
              },
            },
            lgAssignees: {
              keyArgs: ["filter", "orderBy"],
              merge(
                existing = {
                  edges: [],
                },
                incoming,
                options,
              ) {
                const {
                  args: { after, offset },
                  readField,
                } = options;

                if (offset >= 0) {
                  return {
                    ...incoming,
                    edges: [...(existing?.edges || []), ...(incoming?.edges || [])],
                  };
                }

                const existingItems = existing ? existing.edges.slice(0) : [];
                const incomingItems = incoming?.edges || [];

                let next = offsetFromCursor(existingItems, after, readField);
                // If we couldn't find the cursor, default to appending to
                // the end of the list, so we don't lose any data.
                if (next < 0) {
                  next = existingItems.length;
                }

                // this prevents from adding duplicates
                const merged = [...existingItems.slice(0, next), ...incomingItems];

                return {
                  ...incoming,
                  edges: merged,
                };
              },
            },
            nzrNotifications: {
              keyArgs: ["filter", "orderBy"],
              merge(
                existing = {
                  edges: [],
                },
                incoming,
                options,
              ) {
                const {
                  args: { after },
                  readField,
                } = options;

                const existingItems = existing ? existing.edges.slice(0) : [];
                const incomingItems = incoming?.edges || [];

                let offset = offsetFromCursor(existingItems, after, readField);
                // If we couldn't find the cursor, default to appending to
                // the end of the list, so we don't lose any data.
                if (offset < 0) {
                  offset = existingItems.length;
                }

                // this prevents from adding duplicates
                const merged = [...existingItems.slice(0, offset), ...incomingItems];

                return {
                  ...incoming,
                  edges: merged,
                };
              },
            },
            // todo duplicate code
            nzrSentimentForms: {
              keyArgs: ["filter", "orderBy"],
              merge(
                existing = {
                  edges: [],
                },
                incoming,
                options,
              ) {
                const {
                  args: { after },
                  readField,
                } = options;
                const existingItems = existing ? existing.edges.slice(0) : [];
                const incomingItems = incoming?.edges || [];

                let offset = offsetFromCursor(existingItems, after, readField);
                // If we couldn't find the cursor, default to appending to
                // the end of the list, so we don't lose any data.
                if (offset < 0) {
                  offset = existingItems.length;
                }

                // this prevents from adding duplicates
                const merged = [...existingItems.slice(0, offset), ...incomingItems];

                return {
                  ...incoming,
                  edges: merged,
                };
              },
            },
          },
        },
        LgCompanyFlags: {
          merge: false,
        },
      },
    }),
  });
}

function offsetFromCursor(items, cursor, readField) {
  // when cursor is not defined, this means we are on the first page
  // thus it's better to return 0 as the offset is used to append items to the array
  // if we return -1, items will be appended to the end of the array resulting in duplicates
  if (!cursor) {
    return 0;
  }

  // Search from the back of the list because the cursor we're
  // looking for is typically the "cursor" field of the last item.
  for (let i = items.length - 1; i >= 0; --i) {
    const item = items[i];
    if (readField("cursor", item) === cursor) {
      // Add one because the cursor identifies the item just
      // before the first item in the page we care about.
      return i + 1;
    }
  }
  // Report that the cursor could not be found.
  return -1;
}
