import React, { useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";

import { CreateManualOpportunityDataInput } from "@/gql/graphql.ts";

import { cn } from "@/lib/utils.ts";

import { Icon, Toast } from "@/app/components";
import { GetCompanyEmailDocument, useAddCompanyFromEmailMutation } from "@/app/service/company-email.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

import { CompanyData } from "../company-data-step/company-data.tsx";
import { useCompanyData } from "../company-data-step/use-company-data.tsx";
import { CompanySignalSchema } from "../company-signals-step/company-signals-form.ts";
import { CompanySignals } from "../company-signals-step/company-signals.tsx";

import { CompanyRawData } from "./add-company-via-email.types.ts";

const useAddCompanySteps = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);

  return {
    currentStep,
    next: () => setCurrentStep(currentStep + 1),
    back: () => {
      setCurrentStep(currentStep - 1);
    },
    cancel: () => {
      navigate(-1);
    },
  };
};

/**
 * almost the same as AddCompanyView, but adjusted to be inside modal + initial data
 * might make sense to merge with AddCompanyView depending on business logic/UI
 */
export const AddCompanyForm = ({
  candidateCompany,
  onClose,
}: {
  candidateCompany: CompanyRawData;
  onClose: () => void;
}) => {
  const [addCompany, { loading: addingCompany }] = useAddCompanyFromEmailMutation();

  const companyData = useCompanyData();

  const { currentStep, next, back } = useAddCompanySteps();

  useEffect(() => {
    if (candidateCompany?.dealroomCompanyId) {
      companyData.initializeWithDealroomData(candidateCompany.dealroomCompanyId);
    } else {
      companyData.form.reset({
        name: candidateCompany.name,
        websiteUrl: candidateCompany.url,
      });
      // trigger validation because sometimes website is incorrect format
      companyData.form.trigger();
    }
  }, []);

  const handleCreate = (companySignalData: CompanySignalSchema) => {
    const companyMainData = companyData.form.getValues();

    const data: CreateManualOpportunityDataInput = {
      name: companyMainData.name ?? undefined,
      websiteUrl: companyMainData.websiteUrl ?? undefined,
      founders:
        companyMainData.founders
          ?.filter((founder) => founder.dealroomFounder || founder.lgFounder || founder.details)
          .map((founder) => ({
            ...founder,
            dealroomFounder: founder.dealroomFounder?.id,
            lgFounder: founder.lgFounder?.id,
          })) ?? [],
      dealroomCompany: companyMainData.dealroomCompany ?? undefined,
      targetFund: companySignalData.fund,
      isDeepTech: companySignalData.isDeepTech,
      source:
        typeof companySignalData.sourceName === "string"
          ? companySignalData.sourceName
          : companySignalData.sourceName.email,
      sourceType: companySignalData.sourceType,
      canIntro: companySignalData.introAvailable,
      highPriority: companySignalData.highPriority,
      ballInControl: companySignalData.ballInOurControl,
      isActivelyFundraising: companySignalData.activelyRaising,
      alreadyMet: companySignalData.alreadyMet,
      note: companySignalData.note,

      assignees: [authStore.userId],
    };

    addCompany({
      variables: {
        input: {
          company: data,
          candidateCompanyId: candidateCompany.id,
        },
      },
      refetchQueries: [GetCompanyEmailDocument],
    }).then(() => {
      toast.custom((t) => (
        <Toast
          title="Success!"
          visible={t.visible}
          icon={<Icon type={"FillCheck"} className="text-green-500" />}
          subTitle="Company has been added"
          handleClose={() => toast.dismiss(t.id)}
        />
      ));
    });
  };

  const { selectedDealroomCompany } = companyData;
  return (
    <div className="flex w-full bg-white">
      <AnimatePresence initial={false} mode="popLayout">
        {currentStep === 0 && (
          <motion.div
            key={0}
            className="size-full overflow-y-auto"
            initial={{ x: "-100%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-100%", opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <CompanyData
              onSubmit={() => {
                next();
              }}
              onCancel={() => {
                companyData.form.reset();
                onClose();
              }}
              {...companyData}
            />
          </motion.div>
        )}

        {currentStep === 1 && (
          <motion.div
            key={1}
            className={cn("size-full overflow-y-auto")}
            initial={{ x: "100%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "100%", opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <CompanySignals
              onSubmit={(data) => {
                handleCreate(data);
              }}
              onCancel={back}
              loading={addingCompany}
              selectedDealroomCompanyId={selectedDealroomCompany?.id}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
