import { ReactNode } from "react";

import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { Link } from "react-router-dom";

import { LgMember, UserRoleType } from "@/gql/graphql.ts";

import { cn, getFullName, getInitials } from "@/lib/utils.ts";

import { AvatarV2, Tooltip } from "@/app/components";
import { authStore } from "@/app/stores/auth.store.tsx";

import { getNetworkSignalSourceLabel } from "./network-signal-utils.ts";
import { NetworkSignalData } from "./network-signal.types.ts";

export type NetworkSignalView = {
  author: Pick<LgMember, "firstName" | "lastName" | "avatar">;
  note: string;
  attributes: {
    canConnect: boolean;
    isActivelyFundraising: boolean;
    isHighPriority: boolean;
    isDeepTech: boolean;
  };
};

const SourceName = ({ value }) => {
  // todo enable this after we launch network
  // const validator = z.string().email();
  // const emailType = validator.safeParse(value).success;

  const isEmailType = false;

  if (!isEmailType) {
    return value;
  }

  return (
    <a target="_blank" href={`/network/contact/${value}`} rel="noreferrer">
      {value}
    </a>
  );
};

const SourceEmail = ({ signal, hasAccess }: { signal: NetworkSignalData; hasAccess: boolean }) => {
  if (!hasAccess) {
    return <span className="text-sm font-normal text-gray-600">{signal.sourceEmail}</span>;
  }

  return (
    <Link className="text-sm font-normal text-gray-600" to={`/network/contact/${signal.sourceEmail}`}>
      {signal.sourceEmail}
    </Link>
  );
};

const Source = ({ signal, className }: { signal: NetworkSignalData; className?: string }) => {
  const { userRoles: myRoles } = authStore;

  const sourceType = getNetworkSignalSourceLabel(signal.sourceType);

  const hasAccess = myRoles?.includes(UserRoleType.UserRoleNetwork) ?? false;

  return (
    <div className={className}>
      <div className={cn("text-base font-semibold text-black")}>
        {SourceName({ value: signal.sourceName })}{" "}
        <span className="text-sm font-normal text-gray-600">({sourceType})</span>
      </div>
      {signal.sourceEmail && <SourceEmail signal={signal} hasAccess={hasAccess} />}
    </div>
  );
};

const Author = ({ signal, className }: { signal: NetworkSignalData; className?: string }) => {
  const { avatar, firstName, lastName } = signal.createdBy || {};
  const name = getFullName(firstName, lastName);

  return (
    <div className={cn("grid grid-cols-2 items-center gap-x-2 overflow-hidden text-nowrap @xl/signal:flex", className)}>
      <div className="flex flex-col gap-2 overflow-hidden @xl/signal:flex-row @xl/signal:items-center">
        <span className="text-sm text-gray-600">Added by</span>
        <div className="flex items-center gap-2 overflow-hidden">
          <AvatarV2 type="user" src={avatar} size="xs" initials={getInitials(`${firstName} ${lastName}`)} />
          <span className="truncate text-sm font-medium text-gray-900">{name}</span>
        </div>
      </div>
      <div className="flex flex-col gap-2 @xl/signal:flex-row @xl/signal:items-center @4xl/signal:ml-auto">
        <span className="text-sm text-gray-600 @xl/signal:hidden">Date</span>
        <span className="hidden text-sm text-gray-600 @xl/signal:inline">on</span>
        <span className="text-sm">
          {format(new Date(signal.lastUpdatedUtc || signal.createdUtc), "dd MMMM yyyy", { locale: enGB })}
        </span>
      </div>
    </div>
  );
};

const SignalDataValue = ({ label, value, onClick }: { label: string; value: ReactNode; onClick?: () => void }) => {
  return (
    <p
      className={"flex flex-wrap items-center gap-1 text-nowrap text-sm font-medium @lg/signal:flex-nowrap"}
      onClick={onClick}
    >
      <span className={"text-gray-600"}>{label}</span>
      <span className={"text-gray-900"}>{value}</span>
    </p>
  );
};

const Attributes = ({ signal, className }: { signal: NetworkSignalData; className?: string }) => {
  const { attributes } = signal;

  return (
    <div className={cn("grid grid-cols-1 gap-4 @lg/signal:grid-cols-2", className)}>
      <SignalDataValue
        label={"Is the company actively fundraising?"}
        value={attributes.isActivelyFundraising ? "Yes" : "No"}
      />
      <SignalDataValue label={"Is this a high priority?"} value={attributes.isHighPriority ? "Yes" : "No"} />
    </div>
  );
};

const Note = ({ note }: { note?: string | null }) => {
  if (!note) {
    return;
  }

  return (
    <Tooltip containerClassName="justify-start" text={note}>
      <p className={"line-clamp-3 cursor-default text-sm font-normal text-gray-800"}>&quot;{note}&quot;</p>
    </Tooltip>
  );
};

export const NetworkSignalView = ({ signal }: { signal: NetworkSignalData }) => {
  const { note } = signal;

  return (
    <div className="space-y-4 @container/signal">
      <div className="grid grid-cols-1 gap-4 @4xl/signal:grid-cols-5 @7xl/signal:grid-cols-10 @7xl/signal:gap-10">
        <Source signal={signal} className="col-span-1 @4xl/signal:col-span-5 @7xl/signal:col-span-3" />
        <Author signal={signal} className="col-span-1 @4xl/signal:col-span-2 @7xl/signal:col-span-3" />
        <Attributes signal={signal} className="@4xl/signal:col-span-3 @7xl/signal:col-span-4" />
      </div>
      <Note note={note} />
    </div>
  );
};
