import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type MyRolesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MyRolesQuery = {
  __typename?: "Query";
  myRoles?: { __typename?: "MyRolesType"; roles?: Array<Types.UserRoleType | null> | null } | null;
};

export const MyRolesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyRoles" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "myRoles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "roles" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyRolesQuery__
 *
 * To run a query within a React component, call `useMyRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRolesQuery(baseOptions?: Apollo.QueryHookOptions<MyRolesQuery, MyRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyRolesQuery, MyRolesQueryVariables>(MyRolesDocument, options);
}
export function useMyRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRolesQuery, MyRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyRolesQuery, MyRolesQueryVariables>(MyRolesDocument, options);
}
export function useMyRolesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MyRolesQuery, MyRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MyRolesQuery, MyRolesQueryVariables>(MyRolesDocument, options);
}
export type MyRolesQueryHookResult = ReturnType<typeof useMyRolesQuery>;
export type MyRolesLazyQueryHookResult = ReturnType<typeof useMyRolesLazyQuery>;
export type MyRolesSuspenseQueryHookResult = ReturnType<typeof useMyRolesSuspenseQuery>;
export type MyRolesQueryResult = Apollo.QueryResult<MyRolesQuery, MyRolesQueryVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgAssignee",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyStage",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgOpenaiCompanyCategory",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkContactsConnectionsAggregated",
      "NetworkSignal",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "WebcrawlerCompanyDatum",
    ],
  },
};
export default result;
