import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CompanySearchQueryVariables = Types.Exact<{
  q: Types.Scalars["String"]["input"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type CompanySearchQuery = {
  __typename?: "Query";
  companySearchV2?: {
    __typename?: "CompanySearchResultV1sConnection";
    nodes: Array<{
      __typename?: "CompanySearchResultV1";
      id?: string | null;
      name?: string | null;
      website?: string | null;
      image?: string | null;
      source?: string | null;
    }>;
  } | null;
};

export const CompanySearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CompanySearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "q" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companySearchV2" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "q" },
                value: { kind: "Variable", name: { kind: "Name", value: "q" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "max" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "website" } },
                      { kind: "Field", name: { kind: "Name", value: "image" } },
                      { kind: "Field", name: { kind: "Name", value: "source" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCompanySearchQuery__
 *
 * To run a query within a React component, call `useCompanySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySearchQuery({
 *   variables: {
 *      q: // value for 'q'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCompanySearchQuery(
  baseOptions: Apollo.QueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables> &
    ({ variables: CompanySearchQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchDocument, options);
}
export function useCompanySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchDocument, options);
}
export function useCompanySearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchDocument, options);
}
export type CompanySearchQueryHookResult = ReturnType<typeof useCompanySearchQuery>;
export type CompanySearchLazyQueryHookResult = ReturnType<typeof useCompanySearchLazyQuery>;
export type CompanySearchSuspenseQueryHookResult = ReturnType<typeof useCompanySearchSuspenseQuery>;
export type CompanySearchQueryResult = Apollo.QueryResult<CompanySearchQuery, CompanySearchQueryVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgAssignee",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyStage",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgOpenaiCompanyCategory",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkContactsConnectionsAggregated",
      "NetworkSignal",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "WebcrawlerCompanyDatum",
    ],
  },
};
export default result;
