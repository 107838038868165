import React, { Fragment } from "react";

import { observer } from "mobx-react";

import { cn } from "@/lib/utils.ts";

import { Button, Card, Icon, Popover } from "@/app/components";
import { titleCase } from "@/app/misc/helpers.ts";
import { MtpHeader, MTPTable, useMeetThePortfolio } from "@/app/screens/investments/components";
import { mtpHover } from "@/app/screens/investments/components/use-meet-the-portfolio.tsx";

const MTPTables: Array<"Focus" | "Emerging" | "Radar"> = ["Focus", "Emerging", "Radar"];

export const FundOverview = observer(function FundOverview() {
  const { data, activeFund, setExpanded, columns, navigate, expanded, overviewData, statisticsData, portfolioMakeup } =
    useMeetThePortfolio();

  return (
    <section className={"flex w-full flex-col space-y-8 overflow-y-auto"}>
      <MtpHeader />
      <div className={"grid w-full flex-1 grid-cols-1 gap-4 p-4 lg:grid-cols-[33%_66%]"}>
        {activeFund.value !== "BC" && (
          <div className={"flex h-fit w-full flex-col gap-y-4"}>
            <BoxInsight title={"Fund Overview"}>
              <>
                <div className={"flex w-full justify-end border-b border-[#F4F4F4]"}>
                  <div className={"flex w-[44%] rounded-t-xs bg-[#F4F4F4]"}>
                    <span className={"w-1/2  text-center text-sm font-medium text-neutral"}>QoQ</span>
                    <span className={"w-1/2  text-center text-sm font-medium text-neutral"}>YoY</span>
                  </div>
                </div>
              </>
              <table className={"flex w-full bg-white"}>
                <tbody className={"flex w-full flex-1 flex-col"}>
                  <>
                    {Object.entries(overviewData).map(([key, { quarter, YoY, QoQ, Element, border }]) => (
                      <tr
                        key={key}
                        className={cn("flex h-8 flex-1", border ? "border-b border-[#F4F4F4] pb-2 pt-1" : "py-1")}
                      >
                        <td className={"flex w-[34%]"}>
                          <Popover
                            childrenContent={mtpHover[key]}
                            placement={"bottom-start"}
                            childrenClassName={"ml-2.5 max-w-[20dvw]"}
                          >
                            <span className={"text-start text-sm font-semibold text-neutral lg:text-xs 2xl:text-sm"}>
                              {key}
                            </span>
                          </Popover>
                        </td>
                        <td className={"flex w-[22%] justify-center text-center"}>
                          <Element label={quarter} />
                        </td>
                        <td className={"flex w-[22%] justify-center text-center"}>
                          <Element label={QoQ?.value} change={QoQ?.change} />
                        </td>
                        <td className={"flex w-[22%] justify-center text-center"}>
                          <Element label={YoY?.value} change={YoY?.change} />
                        </td>
                      </tr>
                    ))}
                  </>
                </tbody>
              </table>
            </BoxInsight>
            <BoxInsight title={"Fund Insights"}>
              <>
                <div className={"mb-1 flex w-full justify-end border-b border-[#F4F4F4] pt-5"} />
              </>
              <table className={"flex w-full pr-2"}>
                <tbody className={"flex w-full flex-col"}>
                  <>
                    {Object.entries(statisticsData).map(([key, { value, Element }]) => (
                      <tr key={key} className={"my-1 flex h-5"}>
                        <td className={"flex w-[50%]"}>
                          <span className={"text-sm font-semibold text-neutral lg:text-xs 2xl:text-sm"}>{key}</span>
                        </td>
                        <td className={"flex w-[50%] justify-end text-center"}>
                          <Element label={value} />
                        </td>
                      </tr>
                    ))}
                  </>
                </tbody>
              </table>
            </BoxInsight>
            <BoxInsight title={"Portfolio Makeup"}>
              <table className={"mt-2 flex w-full bg-white pr-2"}>
                <tbody className={"flex w-full flex-1 flex-col"}>
                  <>
                    {Object.entries(portfolioMakeup).map(([key, { data, popover, aggregate }], index) => {
                      return (
                        <Fragment key={key}>
                          <div
                            className={cn("flex justify-between border-b border-[#F4F4F4] pb-2", index ? "" : "mt-6")}
                          >
                            <div className={"flex space-x-1"}>
                              <span className="text-sm font-semibold">{key}</span>
                              {popover && (
                                <Popover
                                  childrenContent={String(popover)}
                                  placement={"right"}
                                  childrenClassName={"ml-2.5 max-w-[20dvw]"}
                                >
                                  <Icon type={"Info"} className={"size-3 text-neutral-300"} />
                                </Popover>
                              )}
                            </div>
                            {aggregate && (
                              <span className={"flex text-lg font-semibold text-neutral-800"}>{String(aggregate)}</span>
                            )}
                          </div>
                          <div className={"mb-6"}>
                            {Object.entries(data ?? {})
                              .slice(0, expanded?.["Sectors"] ? Object.entries(data ?? {}).length : 7)
                              .map(([key, { color, value, popover, Element }]) => {
                                return (
                                  <tr key={key} className={"my-1 flex w-full flex-1"}>
                                    <td className={"flex w-full"}>
                                      <span className="text-nowrap text-sm font-medium text-neutral lg:text-xs 2xl:text-sm">
                                        {key}
                                      </span>
                                    </td>
                                    <td className={"flex w-full justify-end text-center"}>
                                      {/*<Popover*/}
                                      {/*  childrenContent={popover}*/}
                                      {/*  placement={"right"}*/}
                                      {/*  containerClassName={"w-full justify-end"}*/}
                                      {/*  childrenClassName={"bg-black ml-2 text-white"}*/}
                                      {/*  directionClassName={"bg-black"}*/}
                                      {/*>*/}
                                      <Element color={color} percentage={value} label={value} />
                                      {/*</Popover>*/}
                                    </td>
                                  </tr>
                                );
                              })}
                            {Number(Object.entries(data ?? {}).length) > 7 ? (
                              <Button
                                size="sm"
                                variant="text"
                                className="absolute bottom-0 left-0 w-full"
                                onClick={() => setExpanded("Sectors")}
                                iconLeft={expanded?.["Sectors"] ? "Chevron Up" : "Chevron Down"}
                                text={expanded?.["Sectors"] ? "Show Less" : "Show More"}
                              />
                            ) : null}
                          </div>
                        </Fragment>
                      );
                    })}
                  </>
                </tbody>
              </table>
            </BoxInsight>
          </div>
        )}
        {activeFund.value === "BC" && (
          <div className={"flex h-fit w-full flex-col gap-y-4"}>
            <BoxInsight title={"Portfolio Makeup"}>
              <table className={"flex w-full"}>
                <tbody className={"flex w-full flex-1 flex-col pr-2"}>
                  <>
                    {Object.entries(portfolioMakeup).map(([key, { data, popover, aggregate }]) => {
                      return (
                        <Fragment key={key}>
                          <div className={"mt-6 flex justify-between border-b border-[#F4F4F4] pb-2"}>
                            <div className={"flex space-x-1"}>
                              <span className="text-sm font-semibold">{titleCase(key)}</span>
                              {popover && (
                                <Popover
                                  childrenContent={String(popover)}
                                  placement={"right"}
                                  childrenClassName={"ml-2.5 max-w-[20dvw]"}
                                >
                                  <Icon type={"Info"} className={"size-3 text-neutral-300"} />
                                </Popover>
                              )}
                            </div>
                            {aggregate && (
                              <span className={"flex text-lg font-semibold text-neutral-800"}>{String(aggregate)}</span>
                            )}
                          </div>
                          <div className={""}>
                            {Object.entries(data)
                              .slice(0, expanded?.["Sectors"] ? Object.entries(data).length : 7)
                              .map(([key, { color, value, popover, Element }]) => {
                                return (
                                  <tr key={key} className={"my-1 flex w-full flex-1"}>
                                    <td className={"flex w-full "}>
                                      <span
                                        className={
                                          "text-nowrap text-sm font-semibold text-neutral lg:text-xs 2xl:text-sm"
                                        }
                                      >
                                        {key}
                                      </span>
                                    </td>
                                    <td className={"flex w-full justify-end text-center"}>
                                      {/*<Popover*/}
                                      {/*  childrenContent={popover}*/}
                                      {/*  placement={"right"}*/}
                                      {/*  containerClassName={"w-full justify-end"}*/}
                                      {/*  childrenClassName={"bg-black ml-2 text-white"}*/}
                                      {/*  directionClassName={"bg-black"}*/}
                                      {/*>*/}
                                      <Element color={color} percentage={value} label={value} />
                                      {/*</Popover>*/}
                                    </td>
                                  </tr>
                                );
                              })}
                            {Number(Object.entries(data).length) > 7 ? (
                              <div
                                className={
                                  "absolute bottom-0 left-0 flex h-8 w-full cursor-pointer items-center justify-center rounded-b-md border-2 border-white bg-neutral-100"
                                }
                                onClick={() => setExpanded("Sectors")}
                              >
                                <div className={"flex  items-center "}>
                                  <Icon
                                    type={expanded?.["Sectors"] ? "Chevron Up" : "Chevron Down"}
                                    className={"size-5 group-hover:text-neutral"}
                                  />
                                  <span className={"text-sm font-semibold group-hover:text-neutral"}>
                                    {expanded?.["Sectors"] ? "Show Less" : "Show More"}
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </Fragment>
                      );
                    })}
                  </>
                </tbody>
              </table>
            </BoxInsight>
            <BoxInsight title={"Fund Overview"}>
              <>
                <div className={"flex w-full justify-end border-b border-[#F4F4F4]"}>
                  <div className={"flex w-[22%] rounded-t-xs bg-[#F4F4F4]"}>
                    <span className={"w-full text-center text-sm font-medium text-neutral"}>YoY</span>
                  </div>
                </div>
              </>
              <table className={"flex w-full"}>
                <tbody className={"flex w-full flex-1 flex-col"}>
                  <>
                    {Object.entries(overviewData).map(([key, { quarter, YoY, Element, border }]) => (
                      <tr
                        key={key}
                        className={cn("flex h-8 flex-1", border ? "border-b border-[#F4F4F4] pb-2 pt-1" : "py-1")}
                      >
                        <td className={"flex w-[34%]"}>
                          <Popover
                            childrenContent={mtpHover[key]}
                            placement={"bottom-start"}
                            childrenClassName={"ml-2.5 max-w-[20dvw]"}
                          >
                            <span className={"text-start text-sm font-semibold text-neutral lg:text-xs 2xl:text-sm"}>
                              {key}
                            </span>
                          </Popover>
                        </td>
                        <td className={"flex w-[22%] justify-center text-center"}></td>
                        <td className={"flex w-[22%] justify-center text-center"}>
                          <Element label={quarter} />
                        </td>
                        <td className={"flex w-[22%] justify-center text-center"}>
                          <Element label={YoY?.value} change={YoY?.change} />
                        </td>
                      </tr>
                    ))}
                  </>
                </tbody>
              </table>
            </BoxInsight>
          </div>
        )}
        <div className={"flex h-fit w-full flex-col gap-y-4"}>
          {MTPTables.map((tableType) => {
            const tableData = data.filter(({ company }) => company.extra.companyType === tableType);
            const cols = columns(navigate, tableData, activeFund.value);
            return (
              <MTPTable
                columns={cols}
                key={tableType}
                data={tableData}
                tableType={tableType}
                activeFund={activeFund.value}
                expanded={expanded?.[tableType]}
                defaultSortingCol={activeFund.value === "BC" ? "dealroomScore" : "navToFundSizePercentage"}
                onShowMore={() => setExpanded(tableType)}
              />
            );
          })}
          <div className="h-4 opacity-0">&nbsp;</div>
        </div>
      </div>
    </section>
  );
});

const BoxInsight = ({ title, children }) => {
  return (
    <Card
      className={
        "relative flex h-fit w-full flex-col shadow-surface transition-all duration-200 ease-in-out hover:scale-[1.01]"
      }
    >
      <>
        <div className={"-mb-1.5 flex items-center gap-x-2"}>
          <span className={"text-lg font-semibold text-neutral-800"}>{title}</span>
          <Popover childrenContent={mtpHover[title]} placement={"right"} childrenClassName={"ml-2.5 max-w-[20dvw]"}>
            <Icon type={"Info"} className={"mt-0.5 size-3 text-neutral-300"} />
          </Popover>
        </div>
      </>
      {children}
    </Card>
  );
};
