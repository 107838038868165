import { useState } from "react";

import { cn } from "@/lib/utils.ts";

import { JumpToTop } from "@/app/components";
import { LoadingOverLay } from "@/app/components/loading/loading-overlay.tsx";
import { GenericPageNavSection } from "@/app/components/page-nav";
import { CompanyDetails } from "@/app/screens/opportunities/opportunity/components/details/company-details.tsx";
import { Sentiment } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment.tsx";
import {
  OpportunityProvider,
  useOpportunityContext,
} from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

import {
  Attachments,
  BasecampInfo,
  Comments,
  FundingHistory,
  MetricsSection,
  News,
  PCGOverview,
  SectionTitle,
} from "./components";
import { Founders } from "./components/founders/founders.tsx";
import { Highlights } from "./components/highlights/highlights.tsx";
import { NetworkSignals } from "./components/network-signals/network-signals.tsx";
import { ActionsHeader } from "./components/opportunity-actions.tsx";
import { OpportunityHeaderSticky } from "./components/opportunity-header-sticky.tsx";
import { Section } from "./components/section.tsx";
import { useOpportunity } from "./use-opportunity.tsx";

const Opportunity = () => {
  const [showStickyHeader, toggleHeader] = useState(false);
  const {
    company,
    signal,
    pcgOverview,
    fundingRounds,
    basecampInvestors,
    portCo,
    news,
    sentimentDataSource,
    dataSources,
  } = useOpportunity();

  const { viewMode, loading } = useOpportunityContext();

  if (loading) {
    return (
      <div className="h-screen w-full">
        <LoadingOverLay />
      </div>
    );
  }

  if (!company) {
    return null;
  }

  return (
    <div className="flex h-fit w-full flex-col" id="scroller">
      <OpportunityHeaderSticky isOpen={showStickyHeader} company={company} />

      <div
        className={cn(
          "relative grid h-fit w-full grid-cols-1 space-y-2 px-1 pb-2 md:px-5 md:pb-4 lg:space-y-3 lg:px-10",
          viewMode === "contrast" ? "bg-gray-300" : "bg-[#F1F1F1]",
        )}
      >
        <GenericPageNavSection
          id="actions"
          className="border-none bg-transparent px-1 py-2 md:px-0 lg:px-0 lg:py-4"
          onViewEnter={() => toggleHeader(false)}
          onViewLeave={() => toggleHeader(true)}
        >
          <ActionsHeader company={company} />
        </GenericPageNavSection>

        <Section id="about" className="!mt-0">
          <CompanyDetails />
        </Section>

        <Section id="highlights" className="border-none bg-transparent !p-0">
          <Highlights company={company} signal={signal} portCo={portCo} />
        </Section>

        <NetworkSignals />

        {Boolean(pcgOverview?.data.length) && (
          <Section id="pcg">
            <SectionTitle title={"Phoenix Court Group Overview"} source={pcgOverview?.source} />
            <PCGOverview pcgOverview={pcgOverview?.data} />
          </Section>
        )}

        <Founders companyId={company.id} />

        <Section id={"sentiment"}>
          <Sentiment company={company} />
        </Section>

        <Section id={"performance"}>
          <MetricsSection company={company} />
        </Section>

        {basecampInvestors && (
          <Section id={"basecamp"}>
            <SectionTitle title={"Basecamp"} source={dataSources.dealroom} />
            <BasecampInfo company={company} />
          </Section>
        )}

        <Section id="funding">
          <SectionTitle title={"Funding History"} source={dataSources.dealroom} />
          <FundingHistory fundingRounds={fundingRounds} />
        </Section>

        <Section id="news">
          <SectionTitle title={"Related News"} source={news?.source} />
          <News news={news?.data} />
        </Section>

        <Section id="attachments">
          <SectionTitle title={"Attachments"} />
          <Attachments />
        </Section>

        <Section id="comments" className="border-none bg-transparent !p-0">
          <Comments />
        </Section>
      </div>
      <JumpToTop hideOn={["about"]} />
    </div>
  );
};

export const OpportunityView = () => {
  return (
    <OpportunityProvider>
      <Opportunity />
    </OpportunityProvider>
  );
};
