import { useState } from "react";

import { useBreakpoints } from "@/lib/hooks";
import { cn, parseUrl } from "@/lib/utils";

import { AvatarV2 } from "@/app/components";
import {
  Autocomplete,
  AutocompleteClearButton,
  AutocompleteInput,
  AutocompleteOption,
  AutocompleteOptions,
  AutocompleteProgressSpinner,
  AutocompleteToggleButton,
} from "@/app/components/forms/autocomplete";
import { InputV2 } from "@/app/components/forms/input-v2";
import { Company, SearchType, useSearch } from "@/app/hooks";

const hasFounder = (company) => !!(company?.founders?.totalCount > 0);

interface SearchCompanyProps {
  selectCompany: (company?: Company) => void;
  companySearchType?: SearchType;
  defaultSelected?: Company;
  additionalSearchFilter?: any;
}

const CompanySearchInput = ({
  handleSearch,
  loading,
  selectedCompany,
  onClear,
  placeholder,
  value,
}: {
  handleSearch: (val: string) => void;
  loading?: boolean;
  selectedCompany?: Pick<Company, "image" | "name"> | null;
  onClear?: () => void;
  placeholder?: string;
  value: string;
}) => {
  const displayValue = (company: Company | string | null): string => {
    return typeof company === "string" ? company : company?.name ?? value;
  };

  return (
    <div className="group/select relative">
      {selectedCompany && (
        <AvatarV2
          src={typeof selectedCompany === "object" ? parseUrl(selectedCompany.image) : null}
          type="company"
          size="sm"
          className="absolute left-1.5 top-1/2 z-10 -translate-y-1/2"
        />
      )}
      <AutocompleteInput
        as={InputV2}
        data-cy="search-bar-input"
        className={cn(
          "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
          selectedCompany && "pl-11 pr-2"
        )}
        displayValue={displayValue}
        onChange={(event) => {
          handleSearch(event.target.value);
        }}
        onKeyDown={(event) => {
          if (selectedCompany && (event.key === "Backspace" || event.key === "Delete")) onClear?.();
        }}
        autoComplete="off"
        placeholder={placeholder}
        value={selectedCompany?.name || value}
      />
      <div className="absolute inset-y-0 right-1.5 flex items-center gap-2">
        {loading && <AutocompleteProgressSpinner />}
        {(selectedCompany || !!value) && <AutocompleteClearButton onClick={onClear} />}
        <AutocompleteToggleButton />
      </div>
    </div>
  );
};

const SearchResults = ({ results, withFounder = false, loading, value }) => {
  if (value && !loading && results.length === 0) {
    return (
      <AutocompleteOption
        value={null}
        className={cn(
          "select-none rounded-xxs bg-gray-100 px-3 py-1.5 text-center text-sm",
          "data-[focus]:!bg-gray-200 data-[selected]:bg-gray-100 data-[disabled]:opacity-50"
        )}
        disabled={true}
      >
        No Results found for this query
      </AutocompleteOption>
    );
  }

  return results.map((company) => (
    <AutocompleteOption
      key={company.id ?? company.name}
      value={company}
      className={cn(
        "flex select-none items-center gap-2 p-1 text-sm",
        "data-[focus]:!bg-gray-200 data-[selected]:bg-gray-100 data-[disabled]:opacity-50"
      )}
      disabled={withFounder && !hasFounder(company)}
      data-cy="search-result"
    >
      <AvatarV2 src={parseUrl(company.image)} size="sm" type="company" />
      <div>
        <div className="text-sm">{company.name}</div>
        <div className="text-xs text-gray-600">{company.websiteUrl}</div>
      </div>
      {withFounder && !hasFounder(company) && (
        <span className="ml-auto rounded-xxs bg-gray-100 px-2 py-1 text-xs text-gray-800">Missing founder info</span>
      )}
    </AutocompleteOption>
  ));
};

const SearchOptions = ({ results, value, loading, withFounder = false }) => {
  const { isBigTablet } = useBreakpoints();

  return (
    <AutocompleteOptions
      anchor={isBigTablet ? "bottom" : undefined}
      transition
      className={cn(
        "w-[var(--input-width)] rounded-sm bg-white [--anchor-gap:var(--spacing-1)]",
        "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
        isBigTablet && "border shadow-sm [--anchor-max-height:50vh]",
        "!max-h-64"
      )}
    >
      <div className="sticky top-0 z-[1] bg-white p-1">
        {!value && !loading && (
          <AutocompleteOption
            value={null}
            className={cn(
              "select-none rounded-xxs bg-gray-100 px-3 py-1.5 text-center text-sm",
              "data-[focus]:!bg-gray-200 data-[selected]:bg-gray-100 data-[disabled]:opacity-50"
            )}
            disabled={true}
          >
            Start typing to see results...
          </AutocompleteOption>
        )}
        {loading && (
          <span className="flex h-10 items-center justify-center text-sm text-gray-600">Loading results...</span>
        )}
      </div>
      {results.length > 0 && (
        <SearchResults results={results} withFounder={withFounder} value={value} loading={loading} />
      )}
    </AutocompleteOptions>
  );
};

export const SearchCompanyV2 = ({
  selectCompany,
  companySearchType = "lite",
  defaultSelected,
  additionalSearchFilter,
}: SearchCompanyProps) => {
  const [selected, setSelected] = useState<Company | undefined>(defaultSelected);
  const [value, setValue] = useState("");
  const { search, results, loading } = useSearch(companySearchType);

  const handleSearch = (query: string) => {
    if (!selected) {
      setValue(query);
      search({ name: { fuzzy: query }, ...(additionalSearchFilter || {}) });
    }
  };

  const handleSelect = (company: Company) => {
    selectCompany?.(company);
    setSelected(company);
  };

  const handleReset = () => {
    setValue("");
    setSelected(undefined);
    selectCompany();
  };

  return (
    <div className={cn("flex w-full flex-col")}>
      <Autocomplete
        loading={loading}
        value={selected}
        onQueryChange={handleSearch}
        onChange={handleSelect}
        multiple={false}
        clearOnClose={false}
        inputComponent={
          <CompanySearchInput
            loading={loading}
            handleSearch={handleSearch}
            selectedCompany={selected}
            onClear={handleReset}
            placeholder={"Search..."}
            value={value}
          />
        }
        options={
          <SearchOptions
            results={results}
            value={value}
            loading={loading}
            withFounder={companySearchType === "founders"}
          />
        }
      />
    </div>
  );
};
