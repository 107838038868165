import React, { isValidElement, PropsWithChildren, ReactNode } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { IconButtonV2 } from "@/app/components/button";

type ModalV2Props = {
  isOpen: boolean;
  onClose?: () => void;
  title?: ReactNode;
};

export const ModalV2 = ({ isOpen, onClose, title, children }: PropsWithChildren<ModalV2Props>) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="modal"
          className="fixed inset-0 z-50 flex items-end justify-center bg-black/50 md:items-center"
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div
            className="relative w-full min-w-[300px] max-w-[600px] md:w-1/2 md:max-w-none"
            onClick={(e) => e.stopPropagation()}
          >
            <IconButtonV2 icon="X" className="absolute right-4 top-4" onClick={onClose} />
            <div className="rounded-sm rounded-b-none bg-white py-8 md:rounded-b-sm">
              <div className="flex items-center justify-between px-8 pb-6">
                {!!title && (isValidElement(title) ? title : <h1 className="text-lg font-semibold">{title}</h1>)}
              </div>
              <div className="max-h-[80vh] overflow-auto px-8">{children}</div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
