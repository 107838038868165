import { useEffect, useState } from "react";

import * as Sentry from "@sentry/react";
import { toast } from "react-hot-toast";

import { Icon } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import {
  CompanyFoundersSchema,
  useCompanyDataForm,
} from "@/app/screens/add-company/company-data-step/company-data-form.ts";
import { CompanyChangeData } from "@/app/screens/opportunities/components/company-search-autocomplete.tsx";
import { DealroomCompanyQuery, useDealroomCompanyLazyQuery } from "@/app/service/dealroom-company.gql.ts";
import { useScrapeRequestMutation } from "@/app/service/opportunities.gql.ts";

export type ScrapeDataType = {
  name?: string | null;
  websiteUrl?: string | null;
  image?: string | null;
};

function parseScrapeData(
  scrapeData?: {
    scrapeRequest?: {
      metadata?: {
        name?: string;
        ogName?: string;
        siteName?: string;
        ogSiteName?: string;
        title?: string;
        ogTitle?: string;
        favicon?: string;
        ogUrl?: string;
        ogImage?: string;
        url?: string;
      };
    };
  } | null,
): ScrapeDataType | null {
  if (!scrapeData) {
    return null;
  }

  const { metadata } = scrapeData.scrapeRequest ?? {};

  const name =
    metadata?.name ??
    metadata?.ogName ??
    metadata?.siteName ??
    metadata?.ogSiteName ??
    metadata?.title ??
    metadata?.ogTitle ??
    undefined;
  const image = metadata?.favicon ?? metadata?.ogImage ?? undefined;
  const websiteUrl = metadata?.ogUrl ?? metadata?.url ?? undefined;

  return {
    name,
    image,
    websiteUrl,
  };
}

const useScrapeCompany = () => {
  const [scrape] = useScrapeRequestMutation();
  const [scrapeData, setScrapeData] = useState<ScrapeDataType | null>(null);

  const scrapeWebsite = (url: string) => {
    // THIS IS A CUSTOM TOAST REQUESTED BY OUR MASTER LUIGI
    // TODO: replace this shit with an updated toast when TOAST COMPONENT make it to the new system design
    toast.custom(
      ({ id }) => (
        <div className={"flex items-center space-x-2 rounded-xs bg-white px-4 py-3 shadow-md"}>
          <Icon type={"Info"} className={"size-4 text-geek-blue-500"} />
          <div className={"text-sm font-semibold"}>{`Nazare will visit ${url} and gather relevant data.`}</div>
          <IconButtonV2 icon={"X"} size={"xs"} className={""} onClick={() => toast.dismiss(id)} />
        </div>
      ),
      {
        duration: 3000,
      },
    );
    scrape({
      variables: {
        scrapeInput: {
          url,
        },
      },
    })
      .then(({ data }) => {
        const parsed = parseScrapeData(data);
        setScrapeData(parsed);
      })
      .catch((e) => {
        // silently capture exception by sentry without showing an error toast
        Sentry.captureException(e);
      });
  };

  return {
    scrapeWebsite,
    scrapeData,
  };
};

export function useCompanyData() {
  const { form, founders } = useCompanyDataForm();

  const { setValue, reset } = form;

  const [getDealroomCompany, { loading: dealroomDataLoading }] = useDealroomCompanyLazyQuery();
  const [selectedDealroomCompany, setSelectedDealroomCompany] = useState<DealroomCompanyQuery["dealroomCompany"]>();

  const { scrapeWebsite, scrapeData } = useScrapeCompany();

  useEffect(() => {
    if (!scrapeData || selectedDealroomCompany) return;

    const { name, websiteUrl, image } = scrapeData;

    setValue("name", name, { shouldValidate: true, shouldDirty: true });
    setValue("websiteUrl", websiteUrl, { shouldValidate: true, shouldDirty: true });
    setValue("image", image, { shouldValidate: true, shouldDirty: true });
    setValue("scrapeData", { name, image, websiteUrl }, { shouldValidate: true, shouldDirty: true });
  }, [scrapeData, selectedDealroomCompany]);

  // there might be the case when dealroom company id is provided from parent (i.e add via email) so we want to fetch details and put into the form
  const initializeWithDealroomData = (dealroomCompanyId: number) => {
    getDealroomCompany({
      variables: {
        companyId: dealroomCompanyId,
        includeFounders: true,
      },
    }).then(({ data: res }) => {
      const dealroomCompany = res?.dealroomCompany;
      if (!dealroomCompany) {
        return;
      }

      setSelectedDealroomCompany(dealroomCompany);

      const formDefaults = {
        dealroomCompany: dealroomCompany.id,
        name: dealroomCompany.name ?? undefined,
        websiteUrl: dealroomCompany.websiteUrl ?? undefined,
        founders: dealroomCompany.founders?.nodes
          ?.filter((founder) => !!founder.member)
          .map<NonNullable<CompanyFoundersSchema>[number]>((founder) => ({
            dealroomFounder: {
              id: founder.member!.id,
              name: founder.member!.name ?? "",
              image: founder.member?.image,
              linkedinUrl: founder.member!.linkedinUrl,
              titles: founder.titles,
            },
          })) ?? [{}],
      };

      form.reset(formDefaults);
    });
  };

  const handleSelectCompanyByName = (data?: CompanyChangeData) => {
    if (typeof data === "string") {
      setValue("name", data, { shouldValidate: true, shouldDirty: true });
      setSelectedDealroomCompany(null);
    } else if (data && data.source === "dealroom") {
      setValue("name", data.name ?? undefined, { shouldValidate: true, shouldDirty: true });
      setValue("websiteUrl", data.website, { shouldValidate: true, shouldDirty: true });
      setValue("dealroomCompany", data.id, { shouldValidate: true, shouldDirty: true });

      getDealroomCompany({
        variables: {
          companyId: data.id,
          includeFounders: true,
          includeFunding: true,
        },
      }).then(({ data: res }) => {
        const dealroomCompany = res?.dealroomCompany;
        if (!dealroomCompany) {
          return;
        }

        setSelectedDealroomCompany(dealroomCompany);

        const founders = dealroomCompany?.founders?.nodes ?? [];
        setValue(
          "founders",
          founders.length
            ? founders
                ?.filter((founder) => !!founder.member)
                .map<NonNullable<CompanyFoundersSchema>[number]>((founder) => ({
                  dealroomFounder: {
                    id: founder.member!.id,
                    name: founder.member!.name ?? "",
                    image: founder.member?.image,
                    linkedinUrl: founder.member!.linkedinUrl,
                    titles: founder.titles,
                  },
                }))
            : [{}],
        );
      });
    } else {
      setSelectedDealroomCompany(null);
      reset({ name: null, websiteUrl: null, founders: [{}], dealroomCompany: null });
    }
  };

  return {
    form,
    founders,
    scrapeWebsite,
    scrapeData,
    selectedDealroomCompany,
    dealroomDataLoading,
    handleSelectCompanyByName,
    initializeWithDealroomData,
  };
}

export function isDealroomResult(
  paramCompany: DealroomCompanyQuery["dealroomCompany"] | ScrapeDataType,
): paramCompany is DealroomCompanyQuery["dealroomCompany"] {
  return paramCompany?.["__typeName"] === "DealroomCompany";
}
