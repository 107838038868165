import { createContext, PropsWithChildren, useContext, useState } from "react";

import { toast } from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import { useParams } from "react-router";

import { useCompanyDetails } from "@/app/hooks/use-company-details";
import { useNazareAttachments } from "@/app/screens/opportunities/opportunity/components/attachments/use-nazare-attachments.ts";
import { useComments } from "@/app/screens/opportunities/opportunity/components/comments/use-comments.ts";
import { GetCompanyQuery } from "@/app/service/opportunities.gql.ts";
import { LGMember } from "@/app/stores/members.store";
import { CompanyUserTags } from "@/app/types";
import {
  useNetworkSignals
} from "@/app/screens/opportunities/opportunity/components/network-signals/use-network-signals.ts";

export type OpportunityCompany = GetCompanyQuery["lgCompany"];

// need this as some of the screens cannot display gradations of grey properly and everything blends into a grey mass
export type CompanyViewMode = "normal" | "contrast";

type OpportunityContextData = {
  company: OpportunityCompany;
  signalAssignees: LGMember[];
  pcgLeads: LGMember[];
  userTags: CompanyUserTags;
  badges?: any;
  portCo?: any;
  tagline?: string;
  name?: string;
  image?: string;
  refresh?: () => void;
  loading?: boolean;

  viewMode: CompanyViewMode;
  toggleViewMode: (mode: CompanyViewMode) => void;

  attachments?: ReturnType<typeof useNazareAttachments>;
  comments?: ReturnType<typeof useComments>;
  networkSignals?: ReturnType<typeof useNetworkSignals>;
};

const OpportunityContext = createContext<OpportunityContextData>({
  company: null,
  signalAssignees: [],
  pcgLeads: [],
  userTags: {},
  viewMode: "normal",
  toggleViewMode: () => {},
});

export const OpportunityProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams();

  const { company, signalAssignees, assignees, badges, tagline, image, name, portCo, userTags, refetch, loading } =
    useCompanyDetails(id!);

  const attachments = useNazareAttachments(id);
  const comments = useComments(id);
  const networkSignals = useNetworkSignals(id);

  const [viewMode, setViewMode] = useState<CompanyViewMode>("normal");

  const refresh = async () => {
    if (id) {
      await refetch({ id });
      toast.success("Data has been refreshed", { duration: 1000 });
    }
  };

  const value = {
    company,
    signalAssignees,
    pcgLeads: assignees,
    badges,
    tagline,
    image,
    name,
    portCo,
    userTags,
    refresh,
    loading,
    viewMode,
    toggleViewMode: setViewMode,
    attachments,
    comments,
    networkSignals,
  };

  useHotkeys("mod+j", () => setViewMode((prevState) => (prevState === "normal" ? "contrast" : "normal")));

  return <OpportunityContext.Provider value={value}>{children}</OpportunityContext.Provider>;
};

export const useOpportunityContext = (): OpportunityContextData => {
  const context = useContext(OpportunityContext);
  if (context === undefined) {
    throw new Error("Must be used inside OpportunityProvider");
  }

  return context;
};
