import React, { useEffect, useRef, useState } from "react";

import Markdown from "react-markdown";
import { useParams } from "react-router";

import { cn } from "@/lib/utils.ts";

import { Button, Icon, Modal } from "@/app/components";
import mistralLogo from "@/app/components/icon/png/mistral-ai.png";
import { refreshTokenCall } from "@/app/screens/login/refreshToken.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

type SummaryType = "excitement" | "comments";
export const Summary = ({ summaryType, className }: { summaryType: SummaryType; className?: string }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button
        size="sm"
        iconLeft="Wand"
        onClick={() => setOpen((prevState) => !prevState)}
        className={cn(
          "group/summary-btn absolute right-2 top-2 gap-0 lg:opacity-0 lg:group-hover:opacity-100",
          className,
        )}
      >
        <span className="w-0 overflow-auto truncate opacity-0 transition-all duration-300 group-hover/summary-btn:ml-1 group-hover/summary-btn:w-24 group-hover/summary-btn:opacity-100">
          View Summary
        </span>
      </Button>
      <SummaryPopover open={open} setOpen={setOpen} summaryType={summaryType} />
    </>
  );
};

const callMistral = async (company_id, setText, summaryType) => {
  // todo why?
  if (authStore.expiresAt && authStore.expiresAt < Date.now()) {
    await refreshTokenCall();
  }
  const req = await fetch(`${import.meta.env.VITE_NAZARE_API_DOMAIN}/sentiment/summarise`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_id,
        force_recreate: true,
        summary_type: summaryType,
      }),
  });
  const reader = req.body?.getReader();
  let text = "";
  const readChunk = async () => {
    reader?.read().then(({ value, done }) => {
      if (done) {
        setText((prevState) => ({ ...prevState, done: true, loading: false }));
        return;
      }
      const chunkString = new TextDecoder().decode(value);
      text = text + chunkString;
      setText({ text, loading: true, done: false });

      readChunk();
    });
  };
  await readChunk();
};

type SummaryPopoverProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  summaryType?: SummaryType;
};

export const SummaryPopover = ({ open, setOpen, summaryType }: SummaryPopoverProps) => {
  const { id } = useParams();
  const runOnce = useRef(false);

  const [{ text, done }, setText] = useState({
    text: "",
    loading: false,
    done: false,
  });

  useEffect(() => {
    if (open && !runOnce.current) {
      callMistral(id, setText, summaryType)
        .catch(console.error)
        .finally(() => {
          runOnce.current = true;
        });
    }
  }, [id, open, summaryType]);

  const { displayText, doneTyping } = useAiTypingEffectWithStream(text, done, 30);

  if (!open) {
    return;
  }

  return (
    <Modal
      showX
      bodyContentClassName={"overflow-auto"}
      isOpen={open}
      handleClose={() => {
        setOpen(false);
      }}
      footer={
        <span
          className={cn(
            "my-2 flex-col items-start justify-start py-3 text-gray transition-opacity duration-300 ease-in-out",
            doneTyping ? "opacity-100" : "opacity-0",
          )}
        >
          <span className="text-start text-xxs font-medium text-gray-500">Powered by</span>
          <img src={mistralLogo} className={"w-16"} />
        </span>
      }
    >
      {!text ? (
        <div className={"flex max-h-[340px] w-full flex-1 grow items-center justify-center"}>
          <Icon type={"Loader"} className={"mt-10 size-16 text-gray-300"} />
        </div>
      ) : (
        <div className={"relative flex max-h-[4800px] w-full flex-1 grow overflow-auto"}>
          <Markdown className="h-full text-start leading-6 lg:text-lg xl:text-xl">{displayText}</Markdown>
        </div>
      )}
    </Modal>
  );
};

const useAiTypingEffectWithStream = (text, done, speed = 30) => {
  const [displayText, setDisplayText] = useState("");
  const index = useRef(0);

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (!text.length) {
          return;
        }
        if (done && index.current >= text.length) {
          clearInterval(interval);
          return;
        }
        setDisplayText(text.slice(0, index.current));
        index.current = index.current + 3;
      },
      done ? 10 : speed,
    );
    return () => clearInterval(interval);
  }, [text]);

  return {
    displayText,
    doneTyping: done && index.current >= text.length,
  };
};
