import React, { useState } from "react";

import { cn } from "@/lib/utils.ts";

import {
  Autocomplete,
  AutocompleteClearButton,
  AutocompleteInput,
  AutocompleteOption,
  AutocompleteOptions,
  AutocompleteProgressSpinner,
  AutocompleteToggleButton,
} from "@/app/components/forms/autocomplete.tsx";
import { InputV2 } from "@/app/components/forms/input-v2";
import { useSourceSearchQuery } from "@/app/service/network-signal.gql.ts";

export type NetworkSearchItemData = { name: string; email: string };

const useSearchResults = (): {
  results: { name: string; email: string }[];
  search: (value: string) => void;
  loading: boolean;
  searchTerm: string;
} => {
  const [searchTerm, setSearchTerm] = useState("");

  const { data, loading } = useSourceSearchQuery({
    variables: {
      q: searchTerm,
      limit: 20,
    },
    skip: searchTerm.length < 2,
  });

  const search = (value: string) => {
    setSearchTerm(value);
  };

  const results = (data?.networkContactsSimpleLookup?.nodes ?? []).filter(
    (node): node is { name: string; email: string } => Boolean(node.name && node.email),
  );

  return { results, search, loading, searchTerm };
};

const SearchInput = ({ handleSearch, loading, onClear, placeholder, value }) => {
  const displayValue = (value: NetworkSearchItemData | string | null): string => {
    return typeof value === "string"
      ? value
      : [value?.name, value?.email ? `(${value.email})` : null].filter(Boolean).join(" ") ?? "";
  };

  return (
    <div className="group/select relative">
      <AutocompleteInput
        as={InputV2}
        className={cn(
          "pr-20 focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
        )}
        displayValue={displayValue}
        onChange={(event) => {
          handleSearch(event.target.value);
        }}
        autoComplete="off"
        placeholder={placeholder}
      />
      <div className="absolute inset-y-0 right-1.5 flex items-center gap-2">
        {loading && <AutocompleteProgressSpinner />}
        {!!value && <AutocompleteClearButton onClick={onClear} />}
        <AutocompleteToggleButton />
      </div>
    </div>
  );
};

type NetworkAutocompleteChangeData = string | NetworkSearchItemData;
type NetworkSearchAutocompleteProps = {
  onChange: (val: NetworkAutocompleteChangeData) => void;
  value?: NetworkAutocompleteChangeData;
  placeholder?: string;
};

export const NetworkSearchAutocomplete = ({ onChange, value, placeholder }: NetworkSearchAutocompleteProps) => {
  const { results, loading, search, searchTerm } = useSearchResults();

  const handleSearch = (searchVal: string) => {
    search(searchVal);
  };

  const handleSelect = (val: NetworkSearchItemData | string) => {
    onChange(val);
  };

  const handleClear = () => {
    onChange("");
  };

  return (
    <div>
      <Autocomplete
        loading={loading}
        value={value}
        onQueryChange={handleSearch}
        onChange={handleSelect}
        multiple={false}
        inputComponent={
          <SearchInput
            loading={loading}
            handleSearch={handleSearch}
            onClear={handleClear}
            placeholder={placeholder}
            value={value}
          />
        }
        options={
          <AutocompleteOptions
            anchor="bottom"
            transition
            className={cn(
              "w-[var(--input-width)] rounded-xxs border bg-white [--anchor-gap:var(--spacing-1)]",
              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
              "[--anchor-max-height:350px]",
              "z-10 shadow-sm", // needed because of broken location input
            )}
          >
            <div className="sticky top-0 z-[1] bg-white p-1">
              {!searchTerm && (
                <AutocompleteOption
                  value={null}
                  className={cn(
                    "select-none rounded-xxs bg-neutral-100 px-3 py-1.5 text-center text-sm",
                    "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                  )}
                  disabled={true}
                >
                  Start typing to see the results...
                </AutocompleteOption>
              )}
              {searchTerm && (
                <AutocompleteOption
                  value={searchTerm}
                  className={cn(
                    "select-none rounded-xxs bg-neutral-100 px-3 py-1.5 text-center text-sm",
                    "data-[focus]:!bg-neutral-200 data-[selected]:bg-neutral-100 data-[disabled]:opacity-50",
                    "sticky top-0 z-[1]",
                  )}
                >
                  {`Create "${searchTerm}"`}
                </AutocompleteOption>
              )}
              {loading && (
                <div className="flex h-10 items-center justify-center text-sm text-gray-600">Loading results...</div>
              )}
            </div>
            {results.map((resultItem) => (
              <AutocompleteOption
                key={resultItem.email}
                value={resultItem}
                className={cn(
                  "flex select-none items-center gap-2 overflow-hidden px-3 py-1.5 text-sm",
                  "data-[focus]:!bg-gray-200 data-[selected]:bg-gray-100 data-[disabled]:opacity-50",
                )}
              >
                <div className="w-full">
                  <div className="truncate text-sm">{resultItem.name}</div>
                  <div className="text-xs text-gray-600">{resultItem.email}</div>
                </div>
              </AutocompleteOption>
            ))}
          </AutocompleteOptions>
        }
      ></Autocomplete>
    </div>
  );
};
