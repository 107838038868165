import { useMemo } from "react";

import { useNavigate } from "react-router";

import { parseUrl } from "@/lib/utils.ts";

import { CompanyRawData } from "../add-company-via-email.types.ts";

export const useCompanyData = (
  candidateCompany: CompanyRawData,
): {
  name: string;
  type: "lg" | "dealroom" | "new";
  tagline?: string | null;
  websiteUrl?: string | null;
  image?: string | null;
  tags?: string[];
  openDetails?: () => void;
} => {
  const navigate = useNavigate();

  return useMemo(() => {
    if (candidateCompany.lgCompany) {
      return {
        name: candidateCompany.lgCompany.name,
        type: "lg",
        tagline: candidateCompany.lgCompany.tagline,
        websiteUrl: candidateCompany.lgCompany.websiteUrl,
        image: candidateCompany.lgCompany.image,
        tags: candidateCompany.lgCompany.badges,
        openDetails: () => {
          navigate(`/company/${candidateCompany.lgCompany!.id}`);
        },
      };
    }

    if (candidateCompany.dealroomCompany) {
      return {
        name: candidateCompany.dealroomCompany.name ?? "Unknown",
        type: "dealroom",
        tagline: candidateCompany.dealroomCompany.tagline,
        websiteUrl: candidateCompany.dealroomCompany.websiteUrl,
        image: parseUrl(candidateCompany.dealroomCompany.image),
      };
    }

    return {
      name: candidateCompany.name,
      type: "new",
      tagline: candidateCompany.description,
      websiteUrl: candidateCompany.url,
    };
  }, [candidateCompany]);
};
