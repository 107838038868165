import { useState } from "react";

import { motion } from "framer-motion";
import { useNavigate } from "react-router";

import { CreateManualOpportunityDataInput, ManualFounderDataInput } from "@/gql/graphql.ts";

import { cn } from "@/lib/utils.ts";

import { useCompanyData } from "@/app/screens/add-company/company-data-step/use-company-data.tsx";
import { CompanySignalSchema } from "@/app/screens/add-company/company-signals-step/company-signals-form.ts";
import { CompanySignals } from "@/app/screens/add-company/company-signals-step/company-signals.tsx";
import { useAddOpportunityMutation } from "@/app/service/opportunities.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

import { CompanyData } from "./company-data-step/company-data.tsx";

const useAddCompanySteps = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);

  return {
    currentStep,
    next: () => setCurrentStep(currentStep + 1),
    back: () => {
      setCurrentStep(currentStep - 1);
    },
    cancel: () => {
      navigate(-1);
    },
  };
};

export const AddCompanyView = () => {
  const [addOpportunity, { loading }] = useAddOpportunityMutation();
  const navigate = useNavigate();
  const companyData = useCompanyData();

  const { currentStep, next, back, cancel } = useAddCompanySteps();

  const handleCreate = (companySignalData: CompanySignalSchema) => {
    const companyMainData = companyData.form.getValues();

    const data: CreateManualOpportunityDataInput = {
      name: companyMainData.name ?? undefined,
      websiteUrl: companyMainData.websiteUrl ?? undefined,
      founders:
        companyMainData.founders
          ?.filter((founder) => founder.dealroomFounder || founder.lgFounder || founder.details?.name)
          .map((founder) => ({
            details: founder.details as ManualFounderDataInput,
            dealroomFounder: founder.dealroomFounder?.id,
            lgFounder: founder.lgFounder?.id,
          })) ?? [],
      dealroomCompany: companyMainData.dealroomCompany ?? undefined,
      targetFund: companySignalData.fund,
      isDeepTech: companySignalData.isDeepTech,
      source:
        typeof companySignalData.sourceName === "string"
          ? companySignalData.sourceName
          : companySignalData.sourceName.email,
      sourceType: companySignalData.sourceType,
      canIntro: companySignalData.introAvailable,
      highPriority: companySignalData.highPriority,
      ballInControl: companySignalData.ballInOurControl,
      isActivelyFundraising: companySignalData.activelyRaising,
      alreadyMet: companySignalData.alreadyMet,
      note: companySignalData.note,

      assignees: [authStore.userId],
    };

    addOpportunity({
      variables: {
        input: data,
      },
    }).then((res) => {
      if (res.data?.opportunityNew.id) {
        navigate(`/company/${res.data?.opportunityNew.id}`);
      }
    });
  };

  const { selectedDealroomCompany } = companyData;
  return (
    <div className="flex h-screen w-full overflow-y-auto bg-white">
      <motion.div
        className={cn("", currentStep === 0 ? "block size-full" : "hidden size-0")}
        initial={{ x: "-100%", opacity: 0 }}
        animate={{ x: 0, opacity: currentStep === 0 ? 1 : 0 }}
        exit={{ x: "100%", opacity: 0 }}
        transition={{ duration: 0 }}
      >
        <CompanyData
          onSubmit={() => {
            next();
          }}
          onCancel={cancel}
          {...companyData}
        />
      </motion.div>

      <motion.div
        className={cn("size-full", currentStep === 1 ? "block" : "hidden")}
        initial={{ x: "100%", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: "-100%", opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <CompanySignals
          onSubmit={(data) => {
            handleCreate(data);
          }}
          onCancel={back}
          loading={loading}
          selectedDealroomCompanyId={selectedDealroomCompany?.id}
        />
      </motion.div>
    </div>
  );
};
