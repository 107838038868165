import { isEqual } from "lodash";
import { DateTime } from "luxon";
import { action, computed, makeObservable, reaction } from "mobx";
import { hydrateStore, isHydrated, makePersistable } from "mobx-persist-store";

import * as Types from "@/gql/graphql.ts";

import { mergeDeep } from "@/lib/utils.ts";

import { portfolioDefaultFilters, prospectDefaultFilters, pipelineDefaultFilters } from "@/app/constants";
import {
  basecampFunds,
  basecampInvestorCount,
  citiesList,
  continents,
  countriesList,
  dateList,
  investmentType,
  pcgFunds,
  pipelines,
  pipelineTrelloColumns,
  stages,
  targetFund,
  trelloColumns,
  trelloColumnsList,
} from "@/app/misc/filters.ts";
import opportunitiesStore, { OpportunitiesState } from "@/app/screens/opportunities/opportunities.store.ts";
import OpportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import { authStore } from "@/app/stores/auth.store.tsx";
import {
  extraOptions,
  FilterStore,
  MultiSelectCategories,
  RangeCategories,
  TagCategory,
} from "@/app/stores/filter.store.tsx";
import membersStore from "@/app/stores/members.store.tsx";
import MembersStore from "@/app/stores/members.store.tsx";
import { industries } from "@/data/data.tsx";

const WriteOffIds = {
  "Write Off": "56033a8db8bdf17fed345c51",
  Exit: "556104a770ab0acf382d095c",
};

const makeExclusionForWriteOffs = (values) => {
  return values.length > 0
    ? {
        and: {
          companyStageId: {
            notIn: values.map((item) => WriteOffIds[item]),
          },
        } satisfies Types.LgCompanyFilter,
      }
    : {};
};

const sharedFilters = (filters) => {
  return {
    follow: {
      choice: { tag: { follow: true } },
      filters,
      order: { value: "DEALROOM_SIGNAL_RATING", dir: "DESC" },
    },
    hide: {
      choice: { tag: { hide: true } },
      filters,
      order: { value: "DEALROOM_SIGNAL_RATING", dir: "DESC" },
    },
  };
};

const filtersByTab = ({ path, quickView }) => {
  const options = {
    portfolio: {
      led_by: {
        choice: {
          multiselect: {
            "Led By": [`${authStore?.user?.firstName} ${authStore?.user?.lastName}`],
          },
        },
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      },
      supported_by: {
        choice: {
          multiselect: {
            "Supported By": [`${authStore?.user?.firstName} ${authStore?.user?.lastName}`],
          },
        },
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      },
      // value_drivers: {
      //   choice: {
      //     range: {
      //       "Total NAV": [5, 50],
      //     },
      //   },
      //   order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      // },
      focus_companies: {
        choice: {
          // multiselect: {
          //   Companies: [
          //     "Zego",
          //     "YuLife",
          //     "Yapily",
          //     "Wagestream",
          //     "Travelperk",
          //     "TravelPerk",
          //     "Tide",
          //     "Taster",
          //     "Motorway",
          //     "Melio",
          //     "Hailo",
          //     "Goodlord",
          //     "Cuvva",
          //     "Cleo",
          //     "Avant Arte",
          //     "Automata",
          //     "At-Bay",
          //   ],
          // },
          range: {
            "NAV as a % of Fund": [10, 15000],
          },
        },
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      },
      liquidity: {
        choice: {},
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      },
      making_investment_offer: {
        choice: {
          multiselect: {
            "Prospect Stage": [
              "LG Making Investment Offer",
              "LT - Making Investment Offer",
              "Solar Making Investment Offer",
            ],
          },
        },
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      },
      all: {
        choice: {},
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
        filters: portfolioDefaultFilters,
      },
      ...sharedFilters(portfolioDefaultFilters),
    },
    prospects: {
      my_prospects: {
        choice: {
          multiselect: {
            "Tagged In": [`${authStore?.user?.firstName} ${authStore?.user?.lastName}`],
          },
        },
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      },
      test_chemistry: {
        choice: {
          multiselect: {
            "Prospect Stage": [
              "LG Test Chemistry & EV Filter",
              "LT - Test Chemistry/10x Filter",
              "Solar Pipeline - Chemistry",
            ],
          },
        },
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      },
      developing_conviction: {
        choice: {
          multiselect: {
            "Prospect Stage": [
              "LG Developing Conviction",
              "LT Developing Conviction",
              "Solar Prospects",
              "Solar Deep Dives",
            ],
          },
        },
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      },
      making_investment_offer: {
        choice: {
          multiselect: {
            "Prospect Stage": [
              "LG Making Investment Offer",
              "LT - Making Investment Offer",
              "Solar Making Investment Offer",
            ],
          },
        },
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      },
      legals: {
        choice: {
          multiselect: {
            "Prospect Stage": ["LG Legals (next 4 weeks)", "LT Legals", "Solar/X Legals"],
          },
        },
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
      },
      all: {
        choice: {},
        order: { value: "LATEST_EXCITEMENT", dir: "DESC" },
        filters: prospectDefaultFilters,
      },
      ...sharedFilters(prospectDefaultFilters),
    },
    pipeline: {
      previously_met: {
        choice: {
          tag: {
            "Previously Met": true,
          },
        },
        order: { value: "DEALROOM_SIGNAL_RATING", dir: "DESC" },
      },
      basecamp: {
        choice: { tag: { Basecamp: true } },
        order: { value: "DEALROOM_SIGNAL_RATING", dir: "DESC" },
      },
      all: {
        choice: {},
        filters: pipelineDefaultFilters,
        order: { value: "DEALROOM_SIGNAL_RATING", dir: "DESC" },
      },
      ...sharedFilters(pipelineDefaultFilters),
    },
    investments: {
      value_drivers: {
        choice: {
          range: {
            "Total NAV": [5, 50],
          },
        },
        order: {},
      },
      liquidity: {
        choice: {},
        filters: {},
        order: {},
      },
      focus_companies: {
        choice: {},
        filters: {},
        order: {},
      },
      lg12: {
        choice: {
          multiselect: {
            "Phoenix Court Group Funds": ["LG12"],
          },
        },
        filters: {},
        order: {},
      },
      lg11: {
        choice: {
          multiselect: {
            "Phoenix Court Group Funds": ["LG11"],
          },
        },
        filters: {},
        order: {},
      },
      lgx: {
        choice: {
          multiselect: {
            "Phoenix Court Group Funds": ["LGX"],
          },
        },
        filters: {},
        order: {},
      },
      lg8: {
        choice: {
          multiselect: {
            "Phoenix Court Group Funds": ["LG8"],
          },
        },
        filters: {},
        order: {},
      },
      lg7: {
        choice: {
          multiselect: {
            "Phoenix Court Group Funds": ["LG7"],
          },
        },
        filters: {},
        order: {},
      },
      lt3: {
        choice: {
          multiselect: {
            "Phoenix Court Group Funds": ["LT3"],
          },
        },
        filters: {},
        order: {},
      },
      lt2: {
        choice: {
          multiselect: {
            "Phoenix Court Group Funds": ["LT2"],
          },
        },
        filters: {},
        order: {},
      },
      lt1: {
        choice: {
          multiselect: {
            "Phoenix Court Group Funds": ["LT1"],
          },
        },
        filters: {},
        order: {},
      },
      sf1: {
        choice: {
          multiselect: {
            "Phoenix Court Group Funds": ["SF1"],
          },
        },
        filters: {},
        order: {},
      },
      all: {
        choice: {},
        filters: {},
        order: {},
      },
    },
  };
  return options[path][quickView] ?? {};
};

export const filterGroups = [
  { name: "Fund" },
  { name: "Stage" },
  { name: "Sectors & Themes" },
  { name: "Geography" },
  { name: "Internal" },
  { name: "Team" },
  { name: "Financial" },
];

export const filterOrder = {
  portfolio: [
    {
      name: "Raising in 6 Month",
      type: "tag",
      group: "Stage",
    },
    {
      name: "Last Round",
      type: "multiselect",
      group: "Stage",
    },
    {
      name: "Last Round Announced",
      type: "multiselect",
      group: "Stage",
    },
    {
      name: "Last Round Amount",
      type: "range",
      group: "Stage",
    },
    {
      name: "Total Raised",
      type: "range",
      group: "Stage",
    },
    {
      name: "Unicorn",
      type: "tag",
      group: "Stage",
    },
    {
      name: "Futurecorn",
      type: "tag",
      group: "Stage",
    },
    //
    {
      name: "Sectors",
      type: "multiselect",
      group: "Sectors & Themes",
    },
    //
    {
      name: "New Palo Alto",
      type: "tag",
      group: "Geography",
    },
    {
      name: "UK",
      type: "tag",
      group: "Geography",
    },
    {
      name: "EMEA",
      type: "tag",
      group: "Geography",
    },
    {
      name: "Countries",
      type: "multiselect",
      group: "Geography",
    },
    {
      name: "Focus Cities",
      type: "multiselect",
      group: "Geography",
    },
    {
      name: "Continent",
      type: "multiselect",
      group: "Geography",
    },
    //
    {
      name: "Portfolio Company",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Previously Met",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Excitement Exists",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Phoenix Court Group Funds",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Basecamp",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Number of Basecamp Investors",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Basecamp Funds",
      type: "multiselect",
      group: "Internal",
    },

    {
      name: "Top Investor",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Top Investors",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Prospect Stage",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Target Fund",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Last Sentiment Added",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Latest Excitement Score",
      type: "range",
      group: "Internal",
    },
    //
    {
      name: "Led By",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Supported By",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Tagged In",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Assigned To",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Added By Me",
      type: "tag",
      group: "Team",
    },
    {
      name: "Hidden By Me",
      type: "tag",
      group: "Team",
    },
    // {
    //   name: "Pipeline",
    //   type: "multiselect",
    //   group: "multiselect",
    // },
    //
    {
      name: "Include Exits & Write Offs",
      type: "multiselect",
      group: "Financial",
    },
    {
      name: "Investment Type",
      type: "multiselect",
      group: "Financial",
    },

    {
      name: "Total NAV",
      type: "range",
      group: "Financial",
    },
    {
      name: "Total Cost",
      type: "range",
      group: "Financial",
    },
    {
      name: "NAV as a % of Fund",
      type: "range",
      group: "Financial",
    },
    {
      name: "First Dollar to Follow-On",
      type: "range",
      group: "Financial",
    },
  ],
  prospects: [
    {
      name: "Sharing Allowed",
      type: "tag",
      group: "Stage",
    },
    {
      name: "Last Round",
      type: "multiselect",
      group: "Stage",
    },
    {
      name: "Last Round Announced",
      type: "multiselect",
      group: "Stage",
    },
    {
      name: "Last Round Amount",
      type: "range",
      group: "Stage",
    },
    {
      name: "Total Raised",
      type: "range",
      group: "Stage",
    },
    {
      name: "Unicorn",
      type: "tag",
      group: "Stage",
    },
    {
      name: "Futurecorn",
      type: "tag",
      group: "Stage",
    },
    //
    {
      name: "Sectors",
      type: "multiselect",
      group: "Sectors & Themes",
    },
    //
    {
      name: "New Palo Alto",
      type: "tag",
      group: "Geography",
    },
    {
      name: "UK",
      type: "tag",
      group: "Geography",
    },
    {
      name: "EMEA",
      type: "tag",
      group: "Geography",
    },
    {
      name: "Countries",
      type: "multiselect",
      group: "Geography",
    },
    {
      name: "Focus Cities",
      type: "multiselect",
      group: "Geography",
    },
    {
      name: "Continent",
      type: "multiselect",
      group: "Geography",
    },
    //
    {
      name: "Portfolio Company",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Previously Met",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Excitement Exists",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Phoenix Court Group Funds",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Basecamp",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Number of Basecamp Investors",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Basecamp Funds",
      type: "multiselect",
      group: "Internal",
    },

    {
      name: "Top Investor",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Top Investors",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Prospect Stage",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Target Fund",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Last Sentiment Added",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Latest Excitement Score",
      type: "range",
      group: "Internal",
    },
    //
    {
      name: "Led By",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Supported By",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Tagged In",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Assigned To",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Added By Me",
      type: "tag",
      group: "Team",
    },
    {
      name: "Hidden By Me",
      type: "tag",
      group: "Team",
    },
    // {
    //   name: "Pipeline",
    //   type: "multiselect",
    //   group: "multiselect",
    // },
    //
    {
      name: "Include Exits & Write Offs",
      type: "multiselect",
      group: "Financial",
    },
    {
      name: "Investment Type",
      type: "multiselect",
      group: "Financial",
    },

    {
      name: "Total NAV",
      type: "range",
      group: "Financial",
    },
    {
      name: "Total Cost",
      type: "range",
      group: "Financial",
    },
    {
      name: "NAV as a % of Fund",
      type: "range",
      group: "Financial",
    },
    {
      name: "First Dollar to Follow-On",
      type: "range",
      group: "Financial",
    },
  ],
  pipeline: [
    {
      name: "Sharing Allowed",
      type: "tag",
      group: "Stage",
    },
    {
      name: "Last Round",
      type: "multiselect",
      group: "Stage",
    },
    {
      name: "Last Round Announced",
      type: "multiselect",
      group: "Stage",
    },
    {
      name: "Last Round Amount",
      type: "range",
      group: "Stage",
    },
    {
      name: "Total Raised",
      type: "range",
      group: "Stage",
    },
    {
      name: "Unicorn",
      type: "tag",
      group: "Stage",
    },
    {
      name: "Futurecorn",
      type: "tag",
      group: "Stage",
    },
    //
    {
      name: "Sectors",
      type: "multiselect",
      group: "Sectors & Themes",
    },
    //
    {
      name: "New Palo Alto",
      type: "tag",
      group: "Geography",
    },
    {
      name: "UK",
      type: "tag",
      group: "Geography",
    },
    {
      name: "EMEA",
      type: "tag",
      group: "Geography",
    },
    {
      name: "Countries",
      type: "multiselect",
      group: "Geography",
    },
    {
      name: "Focus Cities",
      type: "multiselect",
      group: "Geography",
    },
    {
      name: "Continent",
      type: "multiselect",
      group: "Geography",
    },
    //
    {
      name: "Previously Met",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Excitement Exists",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Basecamp",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Number of Basecamp Investors",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Basecamp Funds",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Top Investor",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Top Investors",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Target Fund",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Last Sentiment Added",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Latest Excitement Score",
      type: "range",
      group: "Internal",
    },
    //
    {
      name: "Assigned To",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Added By Me",
      type: "tag",
      group: "Team",
    },
    {
      name: "Hidden By Me",
      type: "tag",
      group: "Team",
    },
  ],
  investments: [
    {
      name: "Last Round",
      type: "multiselect",
      group: "Stage",
    },
    {
      name: "Last Round Announced",
      type: "multiselect",
      group: "Stage",
    },
    {
      name: "Last Round Amount",
      type: "range",
      group: "Stage",
    },
    {
      name: "Total Raised",
      type: "range",
      group: "Stage",
    },
    {
      name: "Unicorn",
      type: "tag",
      group: "Stage",
    },
    {
      name: "Futurecorn",
      type: "tag",
      group: "Stage",
    },
    //
    {
      name: "Sectors",
      type: "multiselect",
      group: "Sectors & Themes",
    },
    //
    {
      name: "New Palo Alto",
      type: "tag",
      group: "Geography",
    },
    {
      name: "UK",
      type: "tag",
      group: "Geography",
    },
    {
      name: "EMEA",
      type: "tag",
      group: "Geography",
    },
    {
      name: "Countries",
      type: "multiselect",
      group: "Geography",
    },
    {
      name: "Focus Cities",
      type: "multiselect",
      group: "Geography",
    },
    {
      name: "Continent",
      type: "multiselect",
      group: "Geography",
    },
    //
    {
      name: "Led By",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Supported By",
      type: "multiselect",
      group: "Team",
    },
    {
      name: "Tagged In",
      type: "multiselect",
      group: "Team",
    },

    {
      name: "Excitement Exists",
      type: "tag",
      group: "Internal",
    },
    {
      name: "Last Sentiment Added",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Latest Excitement Score",
      type: "range",
      group: "Internal",
    },
    {
      name: "Phoenix Court Group Funds",
      type: "multiselect",
      group: "Internal",
    },
    {
      name: "Companies",
      type: "multiselect",
      group: "Fund",
    },
    {
      name: "Investment Type",
      type: "multiselect",
      group: "Fund",
    },

    {
      name: "Total NAV",
      type: "range",
      group: "Fund",
    },
    {
      name: "Total Cost",
      type: "range",
      group: "Fund",
    },
    {
      name: "First Dollar to Follow-On",
      type: "range",
      group: "Fund",
    },
    {
      name: "NAV as a % of Fund",
      type: "range",
      group: "Fund",
    },
  ],
};

class OpportunityFilterStore extends FilterStore {
  public isHydrated = false;
  public currentPath = "";

  constructor(opportunitiesStore: typeof OpportunitiesStore, membersStore: typeof MembersStore) {
    super();
    makeObservable(this, {
      currentTabChange: action,
      mapChoiceForApi: computed,
    });
    this.opportunitiesStore = opportunitiesStore;
    this.membersStore = membersStore;

    makePersistable(
      this,
      {
        stringify: true,
        debugMode: import.meta.env.VITE_MOBX_DEBUG === "true",
        name: "opportunitiesFilterStore",
        storage: window.localStorage,
        properties: ["choice", "initialFilters", "currentFilters", "ordering", "defaultOrdering"],
      },
      { fireImmediately: true },
    ).catch((e) => console.error("Error hydrating store", e));

    this.waitForHydration();
  }

  async waitForHydration() {
    if (isHydrated(this)) {
      this.isHydrated = true;
      return;
    }

    await hydrateStore(this);
    this.isHydrated = true;
    this.opportunitiesStore = opportunitiesStore;
    this.membersStore = membersStore;
    this.currentPath = this.opportunitiesStore?.state?.currentTab?.path;

    reaction(
      () => this.opportunitiesStore.state?.currentTab,
      (state) => this.currentTabChange(state),
    );
    reaction(
      () => this.membersStore.lgMembers,
      () => {
        const alphabeticallyAsc = (a, b) => a.label.localeCompare(b.label);
        const memberList = this.membersStore?.lgMembers
          .map((value) => ({
            label: `${value.firstName} ${value.lastName}`,
            value: `${value.firstName} ${value.lastName}`,
            id: value.id,
          }))
          .sort(alphabeticallyAsc);
        const pcgList = this.membersStore?.pcgCompanies
          .map((value) => ({
            label: value.name,
            value: value.name,
            id: value.id,
          }))
          .sort(alphabeticallyAsc);
        const topInvestors = this.membersStore?.topInvestors.map((value) => ({
          label: value.name,
          value: value.name,
          id: value.id,
        }));

        this.multiSelectData = {
          Companies: pcgList,
          "Prospect Stage": trelloColumnsList,
          "Target Fund": targetFund,
          Countries: countriesList,
          "Focus Cities": citiesList,
          Continent: continents,
          Sectors: industries,
          "Last Round": stages,
          Pipeline: pipelines,
          "Basecamp Funds": basecampFunds
            .map((item) => ({
              label: item.name,
              value: item.name,
            }))
            .sort(alphabeticallyAsc),
          "Phoenix Court Group Funds": Object.entries(pcgFunds).map(([, value]) => ({
            label: value.name,
            value: value.id,
          })),
          "Led By": memberList,
          "Supported By": memberList,
          "Last Round Announced": dateList,
          "Last Sentiment Added": dateList,
          "Number of Basecamp Investors": basecampInvestorCount,
          "Assigned To": memberList,
          "Tagged In": memberList,
          "Investment Type": investmentType,
          "Top Investors": topInvestors,
          "Include Exits & Write Offs": Object.keys(WriteOffIds).map((value) => ({
            label: value,
            value,
          })),
          // Signal: [],
        };
      },
    );
  }

  currentTabChange(currentTab?: OpportunitiesState["currentTab"]) {
    const { quickView, path } = currentTab || this.opportunitiesStore.state.currentTab;
    const { filters, order, choice } = filtersByTab({ path, quickView });
    const { filters: allFilters } = filtersByTab({ path, quickView: "all" });

    this.currentPath = path.toString();
    this.currentFilters = filterOrder[path];
    this.initialFilters = filters || allFilters;
    if (order) {
      this.defaultOrdering = order;
      this.ordering = order;
    }
    if (choice) {
      this.choice = mergeDeep(FilterStore.defaultChoice, choice);
    }
  }

  get mapChoiceForApi(): Types.InputMaybe<Types.LgCompanyFilter> | null {
    const multiSelectTransform = (key: MultiSelectCategories, getValue = (x) => x) => {
      const values = this.choice.multiselect[key];
      let option = "in";
      switch (this.choiceOption.multiselect[key]) {
        case "Is any of":
          option = extraOptions.multiselect.includes(key) ? "containsAnyKeys" : "in";
          break;
        case "Is none of":
          option = extraOptions.multiselect.includes(key) ? "distinctFrom" : "notIn";
          break;
        case "Is all of":
          option = "containsAllKeys";
          break;
      }
      if (!values?.length) return { in: null };

      return {
        [option]: values.map(getValue),
      };
    };
    const dateRangeTransform = (field: string, key: MultiSelectCategories) => {
      if (!this.choice.multiselect[key]?.length) return null;
      const timeMinus = dateList.find((item) => item.label === this.choice.multiselect[key]?.[0])?.value;
      return {
        [field]: {
          greaterThan: DateTime.now().minus({ days: timeMinus }).toISODate(),
        },
      };
    };
    const tagTransform = (label: TagCategory) => {
      const selected = this.choice.tag[label];
      if (selected === null) return { equalTo: null };

      const option = selected ? "equalTo" : "notEqualTo";

      return { [option]: true };
    };
    const existsTransform = () => {
      const key = "Excitement Exists";
      const val = this.choice.tag[key];
      if (val === null) return { deleteMe: null };
      return { isNull: !val };
    };

    const trelloColumnTransform = () => {
      if (!this.choice.multiselect["Prospect Stage"]?.length) return {};
      const option = this.choiceOption.multiselect["Prospect Stage"] === "Is any of" ? "in" : "notIn";
      return {
        companyStageId: {
          [option]: this.choice.multiselect["Prospect Stage"].map((trelloColumn) => trelloColumns[trelloColumn]) || [],
        },
        // status: { notEqualTo: "archived" }, // todo
      } satisfies Types.LgCompanyFilter;
    };

    const pipelineTransform = (val) => {
      const items = val?.map((value) => pipelineTrelloColumns[value]).flat(1);
      if (!items?.length) return {};
      const option = this.choiceOption.multiselect["Pipeline"] === "Is any of" ? "in" : "notIn";
      return {
        companyStageId: {
          [option]: items,
        },
        // status: { notEqualTo: "archived" }, // todo
      } satisfies Types.LgCompanyFilter;
    };

    const basecampTransform = (val) => {
      const items = val?.map((value) => basecampFunds.find((item) => item.name === value)?.id).filter(Boolean);
      if (!items?.length) return {};
      let option = "in";

      if (this.choiceOption.multiselect["Basecamp Funds"] === "Is all of") {
        return graphqlAndForIn(items, (id) => ({
          lgCompanyInvestorsByCompanyId: {
            some: {
              investorId: { in: [id] },
            },
          },
        }));
      }

      switch (this.choiceOption.multiselect["Basecamp Funds"]) {
        case "Is any of":
          option = "in";
          break;
        case "Is none of":
          option = "notIn";
          break;
      }
      return {
        lgCompanyInvestorsByCompanyId: {
          some: {
            investorId: {
              [option]: items,
            },
          },
        },
      };
    };

    const pcgFundsTransform = (val) => {
      const items = val?.map((value) => pcgFunds.find((item) => item.name === value)?.id).filter(Boolean);
      if (!items?.length) return {};

      if (this.choiceOption.multiselect["Phoenix Court Group Funds"] === "Is all of") {
        return graphqlAndForIn(items, (id) => ({
          srcCompaniesByCompanyId: {
            some: {
              opsCompanyAsSource: {
                opsCompanyfundsByCompanyId: {
                  some: {
                    fundId: {
                      in: id,
                    },
                  },
                },
              },
            },
          },
        }));
      }

      let option;
      switch (this.choiceOption.multiselect["Phoenix Court Group Funds"]) {
        case "Is any of":
          option = "in";
          break;
        case "Is none of":
          option = "notIn";
          break;
      }
      return {
        srcCompaniesByCompanyId: {
          some: {
            opsCompanyAsSource: {
              opsCompanyfundsByCompanyId: {
                some: {
                  fundId: {
                    [option]: items,
                  },
                },
              },
            },
          },
        },
      };
    };

    const hasTopInvestorsIn = (val) => {
      const items = val
        ?.map((value) => this.membersStore.topInvestors.find((item) => item.name === value)?.id)
        .filter(Boolean);
      if (!items?.length) return {};

      let option;
      switch (this.choiceOption.multiselect["Top Investors"]) {
        case "Is any of":
          option = "in";
          break;
        case "Is none of":
          option = "notIn";
          break;
      }
      return {
        lgCompanyInvestorsByCompanyId: {
          some: {
            investor: {
              id: { [option]: items },
            },
          },
        },
      };
    };

    const addedByMeTransform = (val) => {
      const option = this.choiceOption.tag["Added By Me"];
      if (!val) return;
      if (option === "Is") {
        return {
          signalsByCompanyId: {
            some: {
              rule: {
                name: { equalTo: "manual_web" },
              },
              output: { contains: { created_by_id: authStore.userId } },
            },
          },
        };
      }

      return {
        signalsByCompanyId: {
          some: {
            not: { output: { contains: { created_by_id: authStore.userId } } },
          },
        },
      };
    };

    const taggedInTransform = (val) => {
      const items = val
        ?.map(
          (value) =>
            this.membersStore.lgMembers.find((item) => `${item.firstName} ${item.lastName}` === value)?.trelloMemberId,
        )
        .filter(Boolean);
      if (!items?.length) return;

      let option = "containsAnyKeys";

      if (this.choiceOption.multiselect["Tagged In"] === "Is none of") {
        return {
          srcCompaniesByCompanyId: {
            some: {
              trelloCardAsSource: {
                not: {
                  // todo replace with assigned to?
                  memberIds: {
                    containsAnyKeys: items,
                  },
                },
              },
            },
          },
        };
      }

      switch (this.choiceOption.multiselect["Tagged In"]) {
        case "Is any of":
          option = "containsAnyKeys";
          break;
        case "Is all of":
          option = "containsAllKeys";
          break;
      }

      return {
        srcCompaniesByCompanyId: {
          some: {
            trelloCardAsSource: {
              // todo replace with assigned to?
              memberIds: {
                [option]: items,
              },
            },
          },
        },
      };
    };

    const ledBy = (key: "Led By" | "Supported By"): Types.LgCompanyFilter => {
      const val = this.choice.multiselect[key];
      const items = val
        ?.map((value) => this.membersStore.lgMembers.find((item) => `${item.firstName} ${item.lastName}` === value)?.id)
        .filter(Boolean);

      if (!items?.length) return {};

      let option;
      switch (this.choiceOption.multiselect[key]) {
        case "Is any of":
          option = "in";
          break;
        case "Is none of":
          option = "notIn";
          break;
      }

      return {
        lgAssigneesByCompanyId: {
          some: {
            memberId: { [option]: items },
            status: key === "Led By" ? { equalTo: "lead" } : { isNull: true },
          },
        },
      };
    };

    const byUserTag = (key: string) => {
      const tagName = key === "hide" ? "Hidden By Me" : key;
      const tag = this.choice.tag[tagName];

      if (!tag) return;

      return {
        nzrUserCompanyTagsByCompanyId: {
          some: {
            userId: { equalTo: authStore.userId },
            tags: {
              contains: {
                [key]: "1",
              },
            },
          },
        },
      };
    };

    const sharingAllowed = () => {
      const tag = this.choice.tag["Sharing Allowed"];

      if (!tag) return;

      return {
        lgCompanyFlagsByCompanyId: { some: { flag: { equalTo: "sharing_allowed" }, deletedAt: { isNull: true } } },
        isOpCompany: { notEqualTo: true },
      };
    };

    const raisingIn6Month = () => {
      const tag = this.choice.tag["Raising in 6 Month"];

      if (!tag) return;

      return {
        lgCompanyFlagsByCompanyId: { some: { flag: { equalTo: "6_month_runway" }, deletedAt: { isNull: true } } },
      };
    };

    const hidden = () => {
      const tag = this.choice.tag["Hidden By Me"];

      if (tag) return;

      return {
        nzrUserCompanyTagsByCompanyId: {
          none: {
            userId: { equalTo: authStore.userId },
            tags: {
              contains: {
                hide: "1",
              },
            },
          },
        },
      };
    };

    const isUnicorn = (val) => {
      if (!val) return;
      return {
        tags: {
          containsAnyKeys: ["verified Unicorns and $1B exits"],
        },
      };
    };

    const isFuturecorn = (val) => {
      if (!val) return;
      return {
        tags: {
          containsAnyKeys: ["possible Futurecorn"],
        },
      };
    };

    const hasTopInvestor = (val) => {
      if (!val) return;
      return {
        lgCompanyInvestorsByCompanyId: {
          some: {
            investor: {
              lgRanking: { isNull: false },
            },
          },
        },
      };
    };

    const assignedTo = (val) => {
      if (!val?.length) return;
      const memberIds = val?.map(
        (value) => this.membersStore.lgMembers.find((item) => `${item.firstName} ${item.lastName}` === value)?.id,
      );

      let option;
      switch (this.choiceOption.multiselect["Assigned To"]) {
        case "Is any of":
          option = "in";
          break;
        case "Is none of":
          option = "notIn";
          break;
      }

      return {
        signalsByCompanyId: {
          some: {
            nzrOpportunities: {
              some: {
                lgMemberByAssignee: {
                  id: { [option]: memberIds },
                },
                status: { equalTo: "CREATED" },
              },
            },
          },
        },
      };
    };

    const numberRange = (
      field: string,
      key: RangeCategories,
      mapper: any = (field, min, max, itemTransform) => ({
        and: {
          [field]: { lessThanOrEqualTo: itemTransform(max) },
        },
        [field]: {
          greaterThanOrEqualTo: itemTransform(min),
        },
      }),
      itemTransform = (x) => Number(x),
    ) => {
      const val = this.choice.range[key];
      if (!val) return {};
      return mapper(field, val[0], val[1], itemTransform);
    };

    const investmentType = (items) => {
      if (!items?.length) return {};

      return {
        srcCompaniesByCompanyId: {
          some: {
            opsCompanyAsSource: {
              opsCompanyfundsByCompanyId: {
                some: {
                  investmentType: {
                    in: items,
                  },
                },
              },
            },
          },
        },
      };
    };

    const isUk = (value) => {
      const selected = value;
      if (selected === null) return null;

      const option = selected ? "in" : "notIn";
      return {
        country: {
          [option]: ["United Kingdom"],
        },
      };
    };
    const isEmea = (value) => {
      const selected = value;
      if (selected === null) return null;

      const option = selected ? "in" : "notIn";
      return {
        continent: {
          [option]: ["Europe", "Africa"],
        },
      };
    };

    const noBasecampInvestors = (items) => {
      if (!items?.length) return {};
      const value = basecampInvestorCount.find(
        (item) => item.label === this.choice.multiselect["Number of Basecamp Investors"]?.[0],
      )?.value;
      return {
        lgCompanyInvestorsByCompanyId: {
          aggregates: {
            filter: {
              investor: {
                isBasecamp: { equalTo: true },
              },
            },
            distinctCount: { id: { greaterThanOrEqualTo: value } },
          },
        },
      };
    };

    const pcgCompanies = (items) => {
      if (!items?.length) return {};
      const pcgCompanyIds = items?.map(
        (value) => this.membersStore.pcgCompanies.find((item) => item.name === value)?.id,
      );

      let option;
      switch (this.choiceOption.multiselect["Companies"]) {
        case "Is any of":
          option = "in";
          break;
        case "Is none of":
          option = "notIn";
          break;
      }

      return {
        srcCompaniesByCompanyId: {
          some: {
            opsCompanyAsSource: {
              id: {
                [option]: pcgCompanyIds,
              },
            },
          },
        },
      };
    };

    let filterObject = Object.fromEntries(
      Object.entries({
        name: { fuzzy: this.choice.search },

        isNpa: tagTransform("New Palo Alto"),
        isOpCompany: tagTransform("Portfolio Company"),
        isBasecampFunded: tagTransform("Basecamp"),
        isTrCompany: tagTransform("Previously Met"),
        targetFund: multiSelectTransform("Target Fund"),
        country: multiSelectTransform("Countries"),
        city: multiSelectTransform("Focus Cities"),
        continent: multiSelectTransform("Continent"),
        industries: multiSelectTransform("Sectors"),
        round: multiSelectTransform("Last Round", (x) => stages.find(({ label }) => label === x)?.value),
        latestExcitement: existsTransform(),

        ...mergeDeep(
          hidden(),
          dateRangeTransform("lastFundingDate", "Last Round Announced"),
          dateRangeTransform("latestExcitementDate", "Last Sentiment Added"),
          trelloColumnTransform(),
          assignedTo(this.choice.multiselect["Assigned To"]),
          addedByMeTransform(this.choice.tag["Added By Me"]),
          isUnicorn(this.choice.tag["Unicorn"]),
          isFuturecorn(this.choice.tag["Futurecorn"]),
          hasTopInvestor(this.choice.tag["Top Investor"]),
          isUk(this.choice.tag["UK"]),
          isEmea(this.choice.tag["EMEA"]),
          pcgCompanies(this.choice.multiselect["Companies"]),

          investmentType(this.choice.multiselect["Investment Type"]),
          pipelineTransform(this.choice.multiselect["Pipeline"]),
          basecampTransform(this.choice.multiselect["Basecamp Funds"]),
          pcgFundsTransform(this.choice.multiselect["Phoenix Court Group Funds"]),
          noBasecampInvestors(this.choice.multiselect["Number of Basecamp Investors"]),
          hasTopInvestorsIn(this.choice.multiselect["Top Investors"]),
          taggedInTransform(this.choice.multiselect["Tagged In"]),
          ledBy("Led By"),
          byUserTag("follow"),
          byUserTag("hide"),
          sharingAllowed(),
          raisingIn6Month(),
          ledBy("Supported By"),
          numberRange(
            "totalInvestedCapital",
            "Total Cost",
            (field, min, max, itemTransform) => ({
              srcCompaniesByCompanyId: {
                some: {
                  opsCompanyAsSource: {
                    and: {
                      [field]: { lessThanOrEqualTo: itemTransform(max) },
                    },
                    [field]: {
                      greaterThanOrEqualTo: itemTransform(min),
                    },
                  },
                },
              },
            }),
            (x) => Number(x) * 1000000,
          ),
          numberRange(
            "totalNav",
            "Total NAV",
            (field, min, max, itemTransform) => ({
              srcCompaniesByCompanyId: {
                some: {
                  opsCompanyAsSource: {
                    and: {
                      [field]: { lessThanOrEqualTo: itemTransform(max) },
                    },
                    [field]: {
                      greaterThanOrEqualTo: itemTransform(min),
                    },
                  },
                },
              },
            }),
            (x) => Number(x) * 1000000,
          ),
          numberRange("amountUsdMillion", "Total Raised"),
          numberRange("totalFunding", "Last Round Amount"),
          numberRange("latestExcitement", "Latest Excitement Score"),
          numberRange(
            "currentNavFromFund",
            "NAV as a % of Fund",
            (field, min, max, itemTransform) => ({
              srcCompaniesByCompanyId: {
                some: {
                  opsCompanyAsSource: {
                    opsCompanyfundsByCompanyId: {
                      some: {
                        and: {
                          [field]: { lessThanOrEqualTo: itemTransform(max) },
                        },
                        [field]: {
                          greaterThanOrEqualTo: itemTransform(min),
                        },
                      },
                    },
                  },
                },
              },
            }),
            (x) => Number(x) / 100,
          ),
          numberRange(
            "initialInvestmentRatio",
            "First Dollar to Follow-On",
            (field, min, max, itemTransform) => ({
              srcCompaniesByCompanyId: {
                some: {
                  opsCompanyAsSource: {
                    and: {
                      [field]: { lessThanOrEqualTo: itemTransform(min) },
                    },
                    [field]: {
                      greaterThanOrEqualTo: itemTransform(max),
                    },
                  },
                },
              },
            }),
            (x) => 1 / Number(x),
          ),
        ),
        // signal: this.choice.multiselect.Signal,
        //What's next?
        // basecamp_investors: this.choice.tag.Basecamp,
      }).filter(([, value]: any[]) => {
        return !Object.entries(value).some(([key, val]) => key != "isNull" && (!val || val?.length === 0));
      }),
    );
    // const { quickView, path } = this.opportunitiesStore.state.currentTab;
    let overrideFilters = { ...this.initialFilters };
    if (this.currentPath === "portfolio") {
      overrideFilters = mergeDeep(
        this.initialFilters,
        makeExclusionForWriteOffs(
          Object.keys(WriteOffIds).filter(
            (item) => !this.choice.multiselect["Include Exits & Write Offs"]?.includes(item),
          ),
        ),
      );
    }

    filterObject = mergeDeep(filterObject, overrideFilters);

    if (!Object.values(filterObject).length) return null;

    return filterObject;
  }

  get showRestorePreset() {
    const { quickView, path } = this.opportunitiesStore.state.currentTab;
    const { choice: defaultChoice } = filtersByTab({ path, quickView });

    const nonEmptyMultiselect = Object.entries(this.choice?.multiselect || []).filter(([, value]) =>
      Boolean(value?.length),
    );

    const nonEmptyTag = Object.entries(this.choice?.tag || []).filter(([, value]) => value !== null);
    const nonEmptyRange = Object.entries(this.choice?.range || []).filter(([, value]) => value !== null);

    const defaultTagAndMultiselect = Object.entries(defaultChoice).flatMap(([, value]: any) => {
      return Object.entries(value || {}).filter(([_, val]) => val !== null || val !== undefined);
    });
    return isEqual([...nonEmptyMultiselect, ...nonEmptyTag, ...nonEmptyRange], defaultTagAndMultiselect);
  }

  opportunitiesStore: typeof OpportunitiesStore;
  membersStore: typeof MembersStore;
}

export default new OpportunityFilterStore(opportunitiesStore, membersStore);

function graphqlAndForIn(ids: string[], transformer: (id: string) => object) {
  function constructStructure(ids, index = 0) {
    if (index === ids.length - 1) {
      return transformer(ids[index]);
    }

    const currentStructure = transformer(ids[index]);
    currentStructure["and"] = constructStructure(ids, index + 1);

    return currentStructure;
  }

  return constructStructure(ids);
}

export const singleSelections = ["Last Round Announced", "Last Sentiment Added", "Number of Basecamp Investors"];
