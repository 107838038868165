import React, { ComponentProps, useState } from "react";

import isEqual from "lodash.isequal";
import { Controller, ControllerRenderProps, UseFormResetField } from "react-hook-form";
import { toast } from "react-hot-toast";

import { cn } from "@/lib/utils.ts";

import { BaseToggle, Button, Icon, Toast, Tooltip } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2";
import { SelectV2 } from "@/app/components/forms/select-v2.tsx";
import { TextAreaV2 } from "@/app/components/forms/textarea-v2.tsx";
import { ChipV2 } from "@/app/components/v2/chip-v2.tsx";
import { LocationInputV2 } from "@/app/screens/opportunities/components/location-input-v2.tsx";
import {
  SourceCompanyChangeData,
  SourceCompanyField,
} from "@/app/screens/opportunities/opportunity/components/details/source-company-field.tsx";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import { getCompanySource } from "@/app/screens/opportunities/utils/company-source.utils.ts";
import { GetCompanyFoundersDocument } from "@/app/service/company-founders.gql.ts";
import { GetCompanyDocument, useUpdateCompanyDetailsMutation } from "@/app/service/opportunities.gql.ts";
import { industries, stages } from "@/data/data.tsx";

import { CompanyDetailsEditFormData, useCompanyDetailsEditForm } from "./company-details-edit-form.ts";

const CompanyLabelBadge = ({
  color,
  visible = false,
  text,
}: {
  color: ComponentProps<typeof ChipV2>["color"];
  visible?: boolean;
  text: string;
}) => {
  if (!visible) {
    return null;
  }

  return <ChipV2 label={text} color={color} className="font-normal" />;
};

const CompanyFieldStatusBadges = ({
  field,
  selectedSourceCompany,
  onReset,
  hideIncomplete,
}: {
  field: ControllerRenderProps<CompanyDetailsEditFormData>;
  selectedSourceCompany?: CompanyDetailsEditFormData | null;
  onReset?: UseFormResetField<CompanyDetailsEditFormData>;
  hideIncomplete?: boolean;
}) => {
  const isIncomplete = hideIncomplete ? false : !field.value;
  const isIdentified = !!selectedSourceCompany?.[field.name] && isEqual(selectedSourceCompany[field.name], field.value);
  const useFromDealroomFn = selectedSourceCompany?.[field.name]
    ? () => field.onChange(selectedSourceCompany?.[field.name])
    : undefined;

  return (
    <div className="ml-1 flex h-6 gap-1">
      <CompanyLabelBadge text="incomplete" color="orange" visible={isIncomplete} />
      <CompanyLabelBadge text="identified" color="green" visible={isIdentified} />
      {isIdentified && typeof onReset === "function" ? (
        <Tooltip text={"Revert to previously saved value"}>
          <IconButtonV2 size="xs" onClick={() => onReset(field.name)} icon="Undo2" />
        </Tooltip>
      ) : null}
      {!isIdentified && typeof useFromDealroomFn === "function" ? (
        <Tooltip text={"Use value from Dealroom"}>
          <IconButtonV2 size="xs" onClick={useFromDealroomFn} icon="Redo2" />
        </Tooltip>
      ) : null}
    </div>
  );
};

export const CompanyDetailsEdit = ({ onClose }) => {
  const { company } = useOpportunityContext();
  const { control, handleSubmit, reset, formState, ...form } = useCompanyDetailsEditForm(company);
  const [selectedSourceCompany, setSelectedSourceCompany] = useState<CompanyDetailsEditFormData | null>(null);
  const existingDealroomSource = getCompanySource(company, "DealroomCompany");

  const [update, { loading }] = useUpdateCompanyDetailsMutation();

  const onSubmit = (data: CompanyDetailsEditFormData) => {
    if (!company) {
      return;
    }

    update({
      variables: {
        companyId: company.id,
        input: {
          name: data.name ?? undefined,
          about: data.description ?? undefined,
          country: data.location?.country ?? undefined,
          city: data.location?.city ?? undefined,
          industries: data.sectors as [string, ...string[]],
          investmentType: data.investmentType ?? undefined,
          round: data.lastRound ?? undefined,
          // targetFund: data.targetFund,
          websiteUrl: data.websiteUrl ?? undefined,
          dealroomCompany: data.dealroomCompany ?? undefined,
          isDeepTech: data.isDeepTech ?? undefined,
        },
      },
      refetchQueries: [GetCompanyDocument, GetCompanyFoundersDocument],
    })
      .then(() => {
        toast.custom((t) => (
          <Toast
            title="Success!"
            visible={t.visible}
            icon={<Icon type={"FillCheck"} className="text-green-500" />}
            subTitle="Company has been updated"
            handleClose={() => toast.dismiss(t.id)}
          />
        ));
        onClose();
      })
      .catch(() => {
        toast.error("Failed to update company. Invalid data");
      });
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  const handleSelectDealroomCompany = (company: SourceCompanyChangeData | null) => {
    setSelectedSourceCompany(company);

    if (company) {
      !!company.name && form.setValue("name", company.name);
      !!company.websiteUrl && form.setValue("websiteUrl", company.websiteUrl);
      !!company.description && form.setValue("description", company.description);
      !!company.location && form.setValue("location", company.location);
      !!company.sectors?.length && form.setValue("sectors", company.sectors);
      !!company.lastRound && form.setValue("lastRound", company.lastRound);
    }
  };

  const showWarningMessage = formState.dirtyFields.dealroomCompany;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <h2 className="text-xl font-semibold">Edit Company</h2>

      <Controller
        name="dealroomCompany"
        control={control}
        render={({ field }) => (
          <SourceCompanyField
            value={field.value}
            onChange={(company) => {
              if (company == null) {
                reset();
              } else {
                field.onChange(company && typeof company === "object" ? company.id : company);
                handleSelectDealroomCompany(company);
              }
            }}
            onUnlink={() => {
              field.onChange(null);
              handleSelectDealroomCompany(null);
            }}
            existingSource={existingDealroomSource}
          />
        )}
      />

      <div className="grid gap-4 lg:grid-cols-2">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <FormField
              label="Company name"
              badge={
                <CompanyFieldStatusBadges
                  field={field}
                  selectedSourceCompany={selectedSourceCompany}
                  onReset={form.resetField}
                  hideIncomplete={company?.status === "LEGACY"}
                />
              }
            >
              <InputV2 placeholder={"Enter company name"} {...field} />
            </FormField>
          )}
        />
        <Controller
          name="websiteUrl"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <FormField
                label="Website URL"
                error={fieldState.error?.message}
                badge={
                  <CompanyFieldStatusBadges
                    field={field}
                    selectedSourceCompany={selectedSourceCompany}
                    onReset={form.resetField}
                    hideIncomplete={company?.status === "LEGACY"}
                  />
                }
              >
                <InputV2
                  {...field}
                  value={field.value || ""}
                  iconLeft={"Continent"}
                  placeholder={"https://websitename.com"}
                />
              </FormField>
            );
          }}
        />
      </div>
      <Controller
        name="description"
        control={control}
        render={({ field, fieldState }) => (
          <FormField
            error={fieldState.error?.message}
            label={"Description"}
            badge={
              <CompanyFieldStatusBadges
                field={field}
                selectedSourceCompany={selectedSourceCompany}
                onReset={form.resetField}
                hideIncomplete={company?.status === "LEGACY"}
              />
            }
          >
            <TextAreaV2
              className="max-h-[230px] w-full"
              placeholder={"Type..."}
              onChange={field.onChange}
              value={field.value}
            />
          </FormField>
        )}
      />

      <div>
        <div className="grid w-full gap-4 lg:grid-cols-2">
          <Controller
            name="location"
            control={control}
            render={({ field, fieldState }) => (
              <FormField
                label="HQ Location"
                error={[fieldState.error?.message, fieldState.error?.country?.message, fieldState.error?.city?.message]
                  .filter(Boolean)
                  .join("; ")}
                badge={
                  <CompanyFieldStatusBadges
                    field={field}
                    selectedSourceCompany={selectedSourceCompany}
                    onReset={form.resetField}
                    hideIncomplete={company?.status === "LEGACY"}
                  />
                }
              >
                <LocationInputV2
                  value={field.value}
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                />
              </FormField>
            )}
          />
          <Controller
            name="sectors"
            control={control}
            render={({ field, fieldState }) => (
              <FormField
                label="Industries"
                error={fieldState.error?.message}
                badge={
                  <CompanyFieldStatusBadges
                    field={field}
                    selectedSourceCompany={selectedSourceCompany}
                    onReset={form.resetField}
                    hideIncomplete={company?.status === "LEGACY"}
                  />
                }
              >
                <SelectV2
                  placeholder="Select..."
                  options={industries}
                  value={field.value || []}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  multiple
                  clearable
                />
              </FormField>
            )}
          />
          <Controller
            name="lastRound"
            control={control}
            render={({ field, fieldState }) => (
              <FormField
                label="Last round raised"
                error={fieldState.error?.message}
                badge={
                  <CompanyFieldStatusBadges
                    field={field}
                    selectedSourceCompany={selectedSourceCompany}
                    onReset={form.resetField}
                    hideIncomplete={company?.status === "LEGACY"}
                  />
                }
              >
                <SelectV2
                  placeholder="Select..."
                  options={stages}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  clearable
                />
              </FormField>
            )}
          />

          <Controller
            name="investmentType"
            control={control}
            render={({ field, fieldState }) => (
              <FormField
                label="Core or Strategic"
                error={fieldState.error?.message}
                badge={
                  <CompanyFieldStatusBadges
                    field={field}
                    selectedSourceCompany={selectedSourceCompany}
                    onReset={form.resetField}
                    hideIncomplete={company?.status === "LEGACY"}
                  />
                }
              >
                <SelectV2
                  placeholder="Select..."
                  options={[
                    { value: "core", label: "Core" },
                    { value: "strategic", label: "Strategic" },
                  ]}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  clearable
                />
              </FormField>
            )}
          />
          <Controller
            name={"isDeepTech"}
            control={control}
            render={({ field }) => (
              <FormField label={"Is this deep tech?"}>
                <Tooltip
                  containerClassName="block"
                  text={"Deep tech companies are enabled by scientific or engineering breakthroughs"}
                >
                  <BaseToggle value={field.value ?? false} onChange={(value) => field.onChange(value)} />
                </Tooltip>
              </FormField>
            )}
          />
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-end gap-4 md:flex-nowrap">
        <div
          className={cn(
            "invisible mr-auto flex w-full items-center gap-2 opacity-0 transition-opacity duration-300 md:w-auto",
            showWarningMessage && "visible opacity-100",
          )}
        >
          <Icon type="InfoOutline" className="size-4 shrink-0 text-orange" />
          <span className="line-clamp-2 text-sm">
            Saving these changes will affect the related data. Review carefully before proceeding.
          </span>
        </div>

        <Button
          className="grow px-10 md:grow-0"
          variant="outline"
          type="button"
          onClick={handleCancel}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          className="grow px-10 md:grow-0"
          variant="gradient"
          type="submit"
          disabled={!formState.isValid || !formState.isDirty}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </form>
  );
};
