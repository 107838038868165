import React from "react";

export const AddCompanyEmailDetails = ({ email }) => {
  return (
    <div className="text-sm">
      Subject: {email.subject}
      {!!email.from && (
        <>
          <br />
          From: {email.from}
        </>
      )}
      {!!email.to && (
        <>
          <br />
          To: {email.to}
        </>
      )}
      <br />
      Source: {email.source}
      <br />
      <br />
      <pre className="whitespace-pre-wrap">{email.body}</pre>
    </div>
  );
};
