import React, { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { cn } from "@/lib/utils.ts";

import { AddCompanyForm } from "../add-company-via-email-form.tsx";
import { CompanyRawData } from "../add-company-via-email.types.ts";

import { CompanyFromEmailCard } from "./company-from-email-card.tsx";

export const ActionRequiredCard = ({ candidateCompany }: { candidateCompany: CompanyRawData }) => {
  const [viewMode, setViewMode] = useState<"view" | "add">("view");

  return (
    <div>
      <CompanyFromEmailCard candidateCompany={candidateCompany} onOpen={() => setViewMode("add")} />
      <AnimatePresence>
        {viewMode === "add" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={cn("fixed inset-0 z-10 flex size-full justify-center overflow-auto bg-white")}>
            <AddCompanyForm candidateCompany={candidateCompany} onClose={() => setViewMode("view")} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
