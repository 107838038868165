import { GetCompanyEmailQuery } from "@/app/service/company-email.gql.ts";

import { CompanyRawData } from "./add-company-via-email.types.ts";

export const useCompaniesFromEmails = (data?: GetCompanyEmailQuery) => {
  const opportunityEmail = data?.nzrNotification?.nzrOpportunityEmail;
  const companiesFromEmails = opportunityEmail?.nzrOpportunityEmailCandidatesByEmailId.nodes ?? [];

  const existing: CompanyRawData[] = companiesFromEmails.filter((company) => !!company.lgCompany && !company.status);
  const completed: CompanyRawData[] = companiesFromEmails.filter(
    (company) => company.status === "COMPLETED" && !!company.lgCompany,
  );
  const declined: CompanyRawData[] = companiesFromEmails.filter((company) => company.status === "DECLINED");
  const actionRequired: CompanyRawData[] = companiesFromEmails.filter(
    (company) => !company.lgCompany && !company.status,
  );

  return {
    existing,
    completed,
    declined,
    actionRequired,
  };
};
