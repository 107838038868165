import { useMemo } from "react";

import { useGetCompanyFoundersQuery } from "@/app/service/company-founders.gql.ts";

import { FounderFromTeam } from "./founders.types.ts";

export const useFounders = (companyId: string) => {
  const { data, loading } = useGetCompanyFoundersQuery({
    variables: { companyId },
  });

  // unique by linkedin url
  const unique: FounderFromTeam[] = useMemo(() => {
    const founders = data?.lgCompanyTeams?.nodes ?? [];

    // get unique by linkedin_url and then name to remove duplicates
    const uniqueByLinkedinUrl = new Map(
      founders
        .filter((founder) => founder.member?.linkedinUrl)
        .map((founder) => {
          const match = founder.member?.linkedinUrl?.match(
            /^(?:https?:\/\/)?(?:www\.|(?:[a-z]{2}\.)?)?(?:linkedin\.com)?\/?(in|pub)\/([\w\p{L}\-%]+)(?:\/|\?|$)/u,
          );
          const profileId = match?.[2] || founder.member?.linkedinUrl;
          return [profileId, founder];
        }),
    );

    const foundersWithoutLinkedinUrl = founders.filter(
      (founder) => !founder.member?.linkedinUrl && founder.member?.name,
    );
    const uniqueByName = new Map(
      [...foundersWithoutLinkedinUrl, ...Array.from(uniqueByLinkedinUrl.values())].map((founder) => [
        founder.member!.name,
        founder,
      ]),
    );

    return Array.from(uniqueByName.values())
      .sort((a) => (a.member?.image ? -1 : 1))
      .map((founder) => ({
        id: founder.member?.id,
        name: founder.member?.name ?? "-",
        linkedinUrl: founder.member?.linkedinUrl,
        image: founder.member?.image,
        titles: founder.titles,
        forms: founder.member?.forms.nodes,
        sourceTypes: founder.member?.founderSources.nodes?.map((node) => node.sourceType) ?? [],
        member: founder.member,
      }));
  }, [data?.lgCompanyTeams?.nodes]);

  return { data: unique, loading };
};
