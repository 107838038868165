import { cn } from "@/lib/utils.ts";

import { Button } from "@/app/components";

type EmailOpportunityActionProps = {
  actions: {
    primary: {
      title: string;
      loading?: boolean;
      action: () => void;
    };
    secondary?: {
      title: string;
      loading?: boolean;
      action: () => void;
    };
  };
  className?: string;
};

export const EmailOpportunityActions = ({ actions, className }: EmailOpportunityActionProps) => {
  const { primary, secondary } = actions;
  return (
    <div className={cn("flex w-full gap-2 md:w-60 md:justify-end", className)}>
      <Button variant="secondary" size="sm" className={cn("w-full md:w-auto")} onClick={primary.action}>
        {primary.title}
      </Button>
      {secondary && (
        <Button
          variant={"outline"}
          onClick={secondary.action}
          size="sm"
          loading={secondary.loading}
          className={cn("w-full md:w-auto")}
        >
          {secondary.title}
        </Button>
      )}
    </div>
  );
};
