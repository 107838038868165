import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type NzrOpportunityEmailQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]["input"];
}>;

export type NzrOpportunityEmailQuery = {
  __typename?: "Query";
  nzrOpportunityEmail?: {
    __typename?: "NzrOpportunityEmail";
    id: any;
    googleMessageId: string;
    isCompleted: boolean;
    isParsed: boolean;
    status?: string | null;
    metadata?: any | null;
    attachments?: any | null;
    body: string;
    subject: string;
    createdAt: any;
    nzrNotifications: {
      __typename?: "NzrNotificationsConnection";
      nodes: Array<{ __typename?: "NzrNotification"; id: any }>;
    };
  } | null;
};

export const NzrOpportunityEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "NzrOpportunityEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrOpportunityEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "googleMessageId" } },
                { kind: "Field", name: { kind: "Name", value: "isCompleted" } },
                { kind: "Field", name: { kind: "Name", value: "isParsed" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "metadata" } },
                { kind: "Field", name: { kind: "Name", value: "attachments" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "subject" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nzrNotifications" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useNzrOpportunityEmailQuery__
 *
 * To run a query within a React component, call `useNzrOpportunityEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNzrOpportunityEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNzrOpportunityEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNzrOpportunityEmailQuery(
  baseOptions: Apollo.QueryHookOptions<NzrOpportunityEmailQuery, NzrOpportunityEmailQueryVariables> &
    ({ variables: NzrOpportunityEmailQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NzrOpportunityEmailQuery, NzrOpportunityEmailQueryVariables>(
    NzrOpportunityEmailDocument,
    options,
  );
}
export function useNzrOpportunityEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NzrOpportunityEmailQuery, NzrOpportunityEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NzrOpportunityEmailQuery, NzrOpportunityEmailQueryVariables>(
    NzrOpportunityEmailDocument,
    options,
  );
}
export function useNzrOpportunityEmailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NzrOpportunityEmailQuery, NzrOpportunityEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NzrOpportunityEmailQuery, NzrOpportunityEmailQueryVariables>(
    NzrOpportunityEmailDocument,
    options,
  );
}
export type NzrOpportunityEmailQueryHookResult = ReturnType<typeof useNzrOpportunityEmailQuery>;
export type NzrOpportunityEmailLazyQueryHookResult = ReturnType<typeof useNzrOpportunityEmailLazyQuery>;
export type NzrOpportunityEmailSuspenseQueryHookResult = ReturnType<typeof useNzrOpportunityEmailSuspenseQuery>;
export type NzrOpportunityEmailQueryResult = Apollo.QueryResult<
  NzrOpportunityEmailQuery,
  NzrOpportunityEmailQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgAssignee",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyStage",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgOpenaiCompanyCategory",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkContactsConnectionsAggregated",
      "NetworkSignal",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "WebcrawlerCompanyDatum",
    ],
  },
};
export default result;
