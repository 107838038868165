import React from "react";

import { cn, parseUrl } from "@/lib/utils.ts";

import { Button, Card, Icon } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import { Tags } from "@/app/screens/opportunities/components/card-list/tags.tsx";
import { GetCompanyEmailDocument, useDeclineCompanyFromEmailMutation } from "@/app/service/company-email.gql.ts";

import { CompanyRawData } from "../add-company-via-email.types.ts";

import { useCompanyData } from "./company-from-email-card-data.ts";

export const CompanyFromEmailCard = ({
  candidateCompany,
  onOpen,
}: {
  candidateCompany: CompanyRawData;
  onOpen?: () => void;
}) => {
  const company = useCompanyData(candidateCompany);

  const [declineCompany, { loading: decliningCompany }] = useDeclineCompanyFromEmailMutation();

  const handleDecline = () => {
    declineCompany({
      variables: {
        input: {
          candidateCompanyId: candidateCompany.id,
        },
      },
      refetchQueries: [GetCompanyEmailDocument],
    });
  };

  const actionRequired = !candidateCompany.status && !candidateCompany.lgCompany;

  return (
    <Card
      className={cn(
        "group relative flex select-none flex-col items-center gap-y-2 transition hover:scale-[1.01]",
        !!company.openDetails && "cursor-pointer",
      )}
      onClick={company.openDetails}
    >
      <Card.Header>
        <Card.Image src={parseUrl(company.image)} size={"sm"} type="company" className="mr-3" />
        <Card.Title title={company.name} />
        {company.type !== "lg" && !!candidateCompany.dealroomCompany?.url && (
          <a href={candidateCompany.dealroomCompany.url} target="_blank" rel="noreferrer">
            <IconButtonV2 icon="Dealroom" />
          </a>
        )}
        {company.type !== "lg" && company.websiteUrl && (
          <a href={candidateCompany.url} target="_blank" rel="noreferrer" className="ml-1">
            <IconButtonV2 icon="Continent" size="sm" />
          </a>
        )}
      </Card.Header>
      <Card.Body>
        <p className="line-clamp-1 min-h-10 text-sm font-medium text-neutral-700">{company.tagline}</p>
      </Card.Body>
      <Card.Footer className="mt-auto">
        <Tags badges={company.tags} />
        {actionRequired && (
          <div className="flex justify-between gap-4">
            <Button size="sm" className="flex-1" variant="outline" onClick={handleDecline} loading={decliningCompany}>
              Skip
            </Button>
            <Button size="sm" className="flex-1" variant="secondary" disabled={decliningCompany} onClick={onOpen}>
              Add to Nazare
            </Button>
          </div>
        )}
      </Card.Footer>
    </Card>
  );
};
