import { useState } from "react";

import { cn } from "@/lib/utils";

import { IconType, SearchBar } from "@/app/components";
import { START_QUERY_AFTER, Company, useSearch, SearchType } from "@/app/hooks";

interface SearchCompanyProps {
  selectCompany: (company: Company) => void;
  companySearchType?: SearchType;
  defaultSelected?: Company | string;
  additionalSearchFilter?: any;
  onReset?: () => void;
  inputClassName?: string;
  iconClassName?: string;
  searchBarClassName?: string;
  placeholder?: string;
  iconType?: IconType;
  showEmptyState?: boolean;
  clearAfterSelection?: boolean;
}

/**
 * @deprecated use SearchCompanyV2 instead
 */
export const SearchCompany = ({
  selectCompany,
  companySearchType = "lg",
  clearAfterSelection = false,
  defaultSelected = "",
  additionalSearchFilter,
  onReset,
  inputClassName,
  iconClassName,
  placeholder,
  iconType,
  searchBarClassName,
  showEmptyState,
}: SearchCompanyProps) => {
  const [selected, setSelected] = useState<Company | string>(defaultSelected);
  const { search, results, loading } = useSearch(companySearchType);

  const handleSearch = (query: string) => {
    const shouldQuery = query.length > START_QUERY_AFTER;
    setSelected("");
    if (shouldQuery) {
      search({ name: { fuzzy: query }, ...(additionalSearchFilter || {}) });
    }
  };

  const handleSelect = (company: Company) => {
    selectCompany?.(company);
    setSelected(company);
  };

  const handleReset = () => {
    setSelected("");
    onReset?.();
  };

  return (
    <div className={cn("relative flex w-full flex-col")}>
      <SearchBar
        clearAfterSelection={clearAfterSelection}
        placeholder={placeholder}
        onQueryChange={handleSearch}
        onReset={handleReset}
        handleSelect={handleSelect}
        className={cn("h-12 w-full", searchBarClassName)}
        inputClassName={inputClassName}
        iconType={iconType}
        selected={selected}
        loading={loading}
        results={results}
        showEmptyState={showEmptyState}
        iconClassName={iconClassName}
      />
    </div>
  );
};
