import React from "react";

import { cn } from "@/lib/utils.ts";

import { GetCompanyEmailQuery } from "@/app/service/company-email.gql.ts";

import { ActionRequiredCard } from "./company-card/action-required-card.tsx";
import { CompanyFromEmailCard } from "./company-card/company-from-email-card.tsx";
import { useCompaniesFromEmails } from "./use-companies-from-email.ts";

const CompaniesSection = ({ title, children }) => {
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">{title}</h2>
      <div className={cn("grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3")}>{children}</div>
    </div>
  );
};

export const AddOpportunitiesViaEmail = ({ data }: { data?: GetCompanyEmailQuery }) => {
  const { existing, completed, declined, actionRequired } = useCompaniesFromEmails(data);

  return (
    <div className="space-y-8">
      {existing.length > 0 && (
        <CompaniesSection title="Already in Nazare">
          {existing.map((company) => (
            <CompanyFromEmailCard key={company.lgCompany?.id ?? company.name} candidateCompany={company} />
          ))}
        </CompaniesSection>
      )}
      {actionRequired.length > 0 && (
        <CompaniesSection title="Action required (not yet in Nazare)">
          {actionRequired.map((company) => (
            <ActionRequiredCard key={company.id} candidateCompany={company} />
          ))}
        </CompaniesSection>
      )}
      {completed.length > 0 && (
        <CompaniesSection title="Added to Nazare">
          {completed.map((company) => (
            <CompanyFromEmailCard key={company.lgCompany?.id ?? company.name} candidateCompany={company} />
          ))}
        </CompaniesSection>
      )}
      {declined.length > 0 && (
        <CompaniesSection title="Skipped">
          {declined.map((company) => (
            <CompanyFromEmailCard key={company.lgCompany?.id ?? company.name} candidateCompany={company} />
          ))}
        </CompaniesSection>
      )}
    </div>
  );
};
