import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useNavigate } from "react-router";

import { TableNew } from "@/app/components/table-new";
import { defaultColumnProps } from "@/app/components/table-new/table-new.config.ts";
import { Company } from "@/app/types/company.ts";

import { useOpportunitiesColumns } from "./opportunities-table-columns.tsx";

type WebListContainerProps = {
  opportunities: Company[];
};

export function TableList({ opportunities }: WebListContainerProps) {
  const navigate = useNavigate();
  const columnDefs = useOpportunitiesColumns();

  const table = useReactTable<Company>({
    columns: columnDefs,
    data: opportunities,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id,
    defaultColumn: defaultColumnProps,
    enableSorting: false,
  });

  return (
    <TableNew
      table={table}
      className="w-full rounded-md bg-white"
      options={{
        onRowClick: (row: Company) => navigate(`/company/${row.id}`),
        density: "dense",
        elementOptions: {
          row: {
            className: "hover:bg-gray-50 transition-colors duration-300 ease-in",
          },
        },
      }}
    />
  );
}
