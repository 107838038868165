import React from "react";

import { Control, Controller } from "react-hook-form";

import { cn, getInitials, parseUrl } from "@/lib/utils.ts";

import { AvatarV2, Button } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button/icon-button-v2.tsx";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2.tsx";
import {
  CompanyDataSchema,
  FoundersFieldArray,
} from "@/app/screens/add-company/company-data-step/company-data-form.ts";
import {
  FounderSearchAutocomplete,
  isDealroomFounder,
  isLgFounder,
} from "@/app/screens/opportunities/components/founder-search-autocomplete.tsx";

const FounderPreview = ({
  founder,
  onRemove,
  disabled,
}: {
  founder: { id: string; linkedinUrl?: string | null; name?: string; image?: string; titles?: { name: string }[] };
  onRemove?: () => void;
  disabled?: boolean;
}) => {
  const titles = typeof founder === "object" && "titles" in founder ? founder.titles : null;

  return (
    <div className={"flex w-full flex-1 items-center rounded-xs border border-gray-300 p-1 pr-2 @container"}>
      <AvatarV2
        src={parseUrl(founder.image)}
        type="user"
        size={"sm"}
        initials={founder.name ? getInitials(founder.name) : ""}
      />
      <div className="ml-2 flex flex-1 flex-col overflow-hidden @md:flex-row @md:items-center">
        <span className="text-nowrap text-sm font-medium">{founder.name}</span>
        {!!titles?.length && (
          <div className="truncate text-sm font-medium text-gray-600 @md:mr-1">
            {titles.map((title) => (
              <React.Fragment key={title.name}>
                <span className="mx-2 first:hidden @md:first:inline">•</span>
                {title?.name}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <div className="ml-auto flex shrink-0 items-center gap-3">
        {founder.linkedinUrl && (
          <a href={founder.linkedinUrl} target="_blank" rel="noreferrer">
            <Button className="hidden @md:block" text="Open LinkedIn" size="xs" variant="outline" type="button" />
            <IconButtonV2 className="@md:hidden" size="xs" variant="outline" type="button" icon="Linkedin" />
          </a>
        )}
        <IconButtonV2 icon="X" className="hidden lg:block" size="xs" onClick={onRemove} disabled={disabled} />
      </div>
    </div>
  );
};

const FounderInputField = ({
  control,
  index = 0,
  handleRemove,
  disableRemove,
}: {
  control: Control<CompanyDataSchema>;
  index?: number;
  handleRemove?: () => void;
  disableRemove?: boolean;
}) => {
  return (
    <Controller
      control={control}
      name={`founders.${index}`}
      render={({ field, fieldState }) => {
        const selectedFounder = field.value.lgFounder ?? field.value.dealroomFounder;

        const hideRemoveButtons = selectedFounder ? false : disableRemove;

        return (
          <div>
            <div className="flex items-center justify-between gap-1 lg:gap-2">
              {!!selectedFounder && (
                <FounderPreview founder={selectedFounder} onRemove={handleRemove} disabled={field.disabled} />
              )}
              {!selectedFounder && (
                <div
                  className={cn(
                    "grid flex-1 gap-2 rounded-xs bg-neutral-200 p-2 lg:grid-cols-2 lg:bg-transparent lg:p-0",
                  )}
                >
                  <FormField error={fieldState.error?.details?.name?.message} className="space-y-0 lg:space-y-2">
                    <FounderSearchAutocomplete
                      value={field.value.details?.name}
                      selectedFounder={selectedFounder}
                      placeholder="Enter founder name"
                      onChange={(founder) => {
                        if (isDealroomFounder(founder)) {
                          field.onChange({ dealroomFounder: founder });
                        } else if (isLgFounder(founder)) {
                          field.onChange({ lgFounder: founder });
                        } else {
                          field.onChange({
                            details: {
                              ...(field.value.details ?? {}),
                              name: founder ?? "",
                            },
                          });
                        }
                      }}
                    />
                  </FormField>
                  <FormField error={fieldState.error?.details?.url?.message}>
                    <div className="flex items-center gap-2">
                      <InputV2
                        iconLeft={"Linkedin"}
                        placeholder="https://linkedin.com/in/user-name"
                        value={field.value?.details?.url || ""}
                        onChange={({ target }) =>
                          field.onChange({
                            ...field.value,
                            details: { ...(field.value.details ?? {}), url: target.value },
                          })
                        }
                        disabled={field.disabled}
                      />
                      {!hideRemoveButtons && (
                        <IconButtonV2
                          icon="Sub"
                          variant="secondary"
                          className="hidden lg:block"
                          onClick={handleRemove}
                          disabled={field.disabled}
                        />
                      )}
                    </div>
                  </FormField>
                </div>
              )}
            </div>
            <div className="relative mt-1 flex w-full justify-center lg:hidden">
              <hr className="absolute top-1/2 w-full" />
              {!hideRemoveButtons && (
                <IconButtonV2
                  icon="Trash"
                  variant="secondary"
                  size="xs"
                  onClick={handleRemove}
                  disabled={field.disabled}
                />
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export const CompanyFoundersFormData = ({
  control,
  fieldArray,
}: {
  fieldArray: FoundersFieldArray;
  control: Control<CompanyDataSchema>;
}) => {
  const { fields, append, remove, replace } = fieldArray;

  const handleAddNewFounder = () => {
    append({});
  };

  const handleRemoveFounder = (index: number) => {
    //   there should be at least one input
    if (fields.length === 1) {
      replace([{}]);
    } else {
      remove(index);
    }
  };

  return (
    <>
      <div className="mb-4 space-y-1 last:space-y-1">
        {fields.map((fieldArrayItem, index, fieldsArr) => (
          <FounderInputField
            key={fieldArrayItem.id}
            control={control}
            index={index}
            handleRemove={() => handleRemoveFounder(index)}
            disableRemove={fieldsArr.length < 2}
          />
        ))}
      </div>
      <div className="mx-auto lg:mx-0 lg:w-1/2 lg:pr-1">
        <Button
          className="px-6"
          type="button"
          variant={"secondary"}
          text={"Add Founder"}
          size={"md"}
          onClick={handleAddNewFounder}
        />
      </div>
    </>
  );
};
