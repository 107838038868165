import React, { useState } from "react";

import { useParams } from "react-router";

import { EmailOpportunityAction } from "@/gql/graphql.ts";

import { useBreakpoints } from "@/lib/hooks";

import { Button, Modal, NativeModal } from "@/app/components";
import { Company } from "@/app/hooks";
import { metricsStore } from "@/app/screens/metrics-extraction";
import { SearchCompany } from "@/app/screens/modal/actions/add-sentiment/components";
import { useSetOpportunityEmailMutation } from "@/app/service/dashboard.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

export const CompanySelect = ({ emailId, notificationId }: { emailId: string; notificationId: string }) => {
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [acceptOpportunityEmail, { loading }] = useSetOpportunityEmailMutation();
  const { id } = useParams();
  const handleApply = () => {
    if (!selectedCompany || !emailId || !id) {
      return;
    }
    if (metricsStore.state.selectedCompanyId === "new") {
      metricsStore.setCompany(selectedCompany?.id, selectedCompany);
      acceptOpportunityEmail({
        variables: {
          input: {
            action: EmailOpportunityAction.Accept,
            emailOpportunityId: emailId,
            companyId: selectedCompany?.id,
            notificationId: notificationId!,
          },
        },
      })
        .then(() => {
          modalStore.close();
        })
        .catch(console.error);
    } else {
      metricsStore.setCompany(selectedCompany?.id, selectedCompany);
      modalStore.close();
    }
  };

  return (
    <div className={"flex w-full flex-col items-start gap-2"}>
      <span className={"font-medium"}>Select a company to link</span>
      <div className="flex w-full items-center gap-4">
        <SearchCompany
          companySearchType={"lite"}
          placeholder={"Search for a company..."}
          // additionalSearchFilter={{ and: { isOpCompany: { equalTo: true } } }}
          selectCompany={(comp) => setSelectedCompany(comp)}
          onReset={() => setSelectedCompany(null)}
        />
        <Button
          variant={"secondary"}
          text={"Apply"}
          onClick={handleApply}
          disabled={!selectedCompany || loading}
          loading={loading}
        />
      </div>
    </div>
  );
};

export const CompanySelectModal = (props: { emailId: string; notificationId: string }) => {
  const { isBigTablet } = useBreakpoints();
  if (isBigTablet) {
    return (
      <Modal isOpen showX={false} handleClose={modalStore.close} bodyContentClassName="p-0">
        <CompanySelect {...props} />
      </Modal>
    );
  }

  return (
    <NativeModal isOpen showX={false} handleClose={modalStore.close}>
      <CompanySelect {...props} />
    </NativeModal>
  );
};
