import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetCompanyEmailQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]["input"];
}>;

export type GetCompanyEmailQuery = {
  __typename?: "Query";
  nzrNotification?: {
    __typename?: "NzrNotification";
    id: any;
    status: string;
    payload: any;
    nzrOpportunityEmail?: {
      __typename?: "NzrOpportunityEmail";
      id: any;
      from?: string | null;
      to?: string | null;
      labels?: any | null;
      subject: string;
      source?: string | null;
      isParsed: boolean;
      isCompleted: boolean;
      status?: string | null;
      parser?: string | null;
      metadata?: any | null;
      senderEmail: string;
      body: string;
      memberId: any;
      nzrOpportunityEmailCandidatesByEmailId: {
        __typename?: "NzrOpportunityEmailCandidatesConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "NzrOpportunityEmailCandidate";
          id: any;
          name: string;
          description: string;
          status?: Types.NzrOpportunityEmailCandidateStatus | null;
          dealroomCompanyId?: number | null;
          url: string;
          dealroomCompany?: {
            __typename?: "DealroomCompany";
            id: any;
            name?: string | null;
            websiteUrl?: string | null;
            websiteDomainFull?: string | null;
            url?: string | null;
            tagline?: string | null;
            image?: string | null;
          } | null;
          lgCompany?: {
            __typename?: "LgCompany";
            id: any;
            name: string;
            websiteUrl?: string | null;
            websiteDomain?: string | null;
            tagline: string;
            badges?: any | null;
            country: string;
            city: string;
            image: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type AddCompanyFromEmailMutationVariables = Types.Exact<{
  input: Types.CreateCompanyViaEmailDataInput;
}>;

export type AddCompanyFromEmailMutation = { __typename?: "Mutation"; addCompanyFromEmail?: any | null };

export type DeclineCompanyFromEmailMutationVariables = Types.Exact<{
  input: Types.DeclineCompanyViaEmailDataInput;
}>;

export type DeclineCompanyFromEmailMutation = { __typename?: "Mutation"; declineEmailCandidateCompany?: any | null };

export type DeclineAddCompanyEmailMutationVariables = Types.Exact<{
  input: Types.DeclineEmailDataInput;
}>;

export type DeclineAddCompanyEmailMutation = { __typename?: "Mutation"; declineCompanyEmail?: any | null };

export const GetCompanyEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompanyEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrNotification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "payload" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "nzrOpportunityEmail" },
                  name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "from" } },
                      { kind: "Field", name: { kind: "Name", value: "to" } },
                      { kind: "Field", name: { kind: "Name", value: "labels" } },
                      { kind: "Field", name: { kind: "Name", value: "subject" } },
                      { kind: "Field", name: { kind: "Name", value: "source" } },
                      { kind: "Field", name: { kind: "Name", value: "isParsed" } },
                      { kind: "Field", name: { kind: "Name", value: "isCompleted" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "parser" } },
                      { kind: "Field", name: { kind: "Name", value: "metadata" } },
                      { kind: "Field", name: { kind: "Name", value: "senderEmail" } },
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                      { kind: "Field", name: { kind: "Name", value: "memberId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nzrOpportunityEmailCandidatesByEmailId" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "status" } },
                                  { kind: "Field", name: { kind: "Name", value: "dealroomCompanyId" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dealroomCompany" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                                        { kind: "Field", name: { kind: "Name", value: "websiteDomainFull" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                        { kind: "Field", name: { kind: "Name", value: "tagline" } },
                                        { kind: "Field", name: { kind: "Name", value: "image" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lgCompany" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                                        { kind: "Field", name: { kind: "Name", value: "websiteDomain" } },
                                        { kind: "Field", name: { kind: "Name", value: "tagline" } },
                                        { kind: "Field", name: { kind: "Name", value: "badges" } },
                                        { kind: "Field", name: { kind: "Name", value: "country" } },
                                        { kind: "Field", name: { kind: "Name", value: "city" } },
                                        { kind: "Field", name: { kind: "Name", value: "image" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyEmailQuery__
 *
 * To run a query within a React component, call `useGetCompanyEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyEmailQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyEmailQuery, GetCompanyEmailQueryVariables> &
    ({ variables: GetCompanyEmailQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyEmailQuery, GetCompanyEmailQueryVariables>(GetCompanyEmailDocument, options);
}
export function useGetCompanyEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyEmailQuery, GetCompanyEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyEmailQuery, GetCompanyEmailQueryVariables>(GetCompanyEmailDocument, options);
}
export function useGetCompanyEmailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyEmailQuery, GetCompanyEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompanyEmailQuery, GetCompanyEmailQueryVariables>(GetCompanyEmailDocument, options);
}
export type GetCompanyEmailQueryHookResult = ReturnType<typeof useGetCompanyEmailQuery>;
export type GetCompanyEmailLazyQueryHookResult = ReturnType<typeof useGetCompanyEmailLazyQuery>;
export type GetCompanyEmailSuspenseQueryHookResult = ReturnType<typeof useGetCompanyEmailSuspenseQuery>;
export type GetCompanyEmailQueryResult = Apollo.QueryResult<GetCompanyEmailQuery, GetCompanyEmailQueryVariables>;
export const AddCompanyFromEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddCompanyFromEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateCompanyViaEmailDataInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addCompanyFromEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "createCompanyViaEmailDataInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AddCompanyFromEmailMutationFn = Apollo.MutationFunction<
  AddCompanyFromEmailMutation,
  AddCompanyFromEmailMutationVariables
>;

/**
 * __useAddCompanyFromEmailMutation__
 *
 * To run a mutation, you first call `useAddCompanyFromEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyFromEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyFromEmailMutation, { data, loading, error }] = useAddCompanyFromEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCompanyFromEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCompanyFromEmailMutation, AddCompanyFromEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCompanyFromEmailMutation, AddCompanyFromEmailMutationVariables>(
    AddCompanyFromEmailDocument,
    options,
  );
}
export type AddCompanyFromEmailMutationHookResult = ReturnType<typeof useAddCompanyFromEmailMutation>;
export type AddCompanyFromEmailMutationResult = Apollo.MutationResult<AddCompanyFromEmailMutation>;
export type AddCompanyFromEmailMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyFromEmailMutation,
  AddCompanyFromEmailMutationVariables
>;
export const DeclineCompanyFromEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeclineCompanyFromEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "DeclineCompanyViaEmailDataInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "declineEmailCandidateCompany" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "declineCompanyViaEmailDataInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeclineCompanyFromEmailMutationFn = Apollo.MutationFunction<
  DeclineCompanyFromEmailMutation,
  DeclineCompanyFromEmailMutationVariables
>;

/**
 * __useDeclineCompanyFromEmailMutation__
 *
 * To run a mutation, you first call `useDeclineCompanyFromEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineCompanyFromEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineCompanyFromEmailMutation, { data, loading, error }] = useDeclineCompanyFromEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineCompanyFromEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclineCompanyFromEmailMutation, DeclineCompanyFromEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineCompanyFromEmailMutation, DeclineCompanyFromEmailMutationVariables>(
    DeclineCompanyFromEmailDocument,
    options,
  );
}
export type DeclineCompanyFromEmailMutationHookResult = ReturnType<typeof useDeclineCompanyFromEmailMutation>;
export type DeclineCompanyFromEmailMutationResult = Apollo.MutationResult<DeclineCompanyFromEmailMutation>;
export type DeclineCompanyFromEmailMutationOptions = Apollo.BaseMutationOptions<
  DeclineCompanyFromEmailMutation,
  DeclineCompanyFromEmailMutationVariables
>;
export const DeclineAddCompanyEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeclineAddCompanyEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "DeclineEmailDataInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "declineCompanyEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "declineEmailDataInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeclineAddCompanyEmailMutationFn = Apollo.MutationFunction<
  DeclineAddCompanyEmailMutation,
  DeclineAddCompanyEmailMutationVariables
>;

/**
 * __useDeclineAddCompanyEmailMutation__
 *
 * To run a mutation, you first call `useDeclineAddCompanyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineAddCompanyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineAddCompanyEmailMutation, { data, loading, error }] = useDeclineAddCompanyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineAddCompanyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclineAddCompanyEmailMutation, DeclineAddCompanyEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineAddCompanyEmailMutation, DeclineAddCompanyEmailMutationVariables>(
    DeclineAddCompanyEmailDocument,
    options,
  );
}
export type DeclineAddCompanyEmailMutationHookResult = ReturnType<typeof useDeclineAddCompanyEmailMutation>;
export type DeclineAddCompanyEmailMutationResult = Apollo.MutationResult<DeclineAddCompanyEmailMutation>;
export type DeclineAddCompanyEmailMutationOptions = Apollo.BaseMutationOptions<
  DeclineAddCompanyEmailMutation,
  DeclineAddCompanyEmailMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgAssignee",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyStage",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgOpenaiCompanyCategory",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkContactsConnectionsAggregated",
      "NetworkSignal",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "WebcrawlerCompanyDatum",
    ],
  },
};
export default result;
