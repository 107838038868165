import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { LgFundName } from "@/gql/graphql.ts";

export enum Fund {
  LocalGlobe = LgFundName.Lg, //"LocalGlobe"
  Latitude = LgFundName.Lt, // "Latitude",
  Solar = LgFundName.Sr, // "Solar",
  Pipeline = "PIPELINE",
}

export const companySignalSchema = z.object({
  fund: z.nativeEnum(Fund).optional(),
  note: z.string().optional().nullable(),
  sourceName: z
    .string({ invalid_type_error: "Please select valid type", required_error: "Source is required" })
    .min(1, "Source is required")
    .or(
      z.object({
        name: z.string(),
        email: z.string().min(1, "Email is required"),
      }),
    ),
  sourceType: z.string({ required_error: "Name is required" }).min(1, "Source type is required"),
  introAvailable: z.boolean().optional().nullable(),
  highPriority: z.boolean().optional().nullable(),
  ballInOurControl: z.boolean().optional().nullable(),
  activelyRaising: z.boolean().optional().nullable(),
  alreadyMet: z.boolean().optional().nullable(),
  isDeepTech: z.boolean().optional().nullable(),
});

export type CompanySignalSchema = z.infer<typeof companySignalSchema>;

export const useCompanySignalForm = () => {
  return useForm<CompanySignalSchema>({
    resolver: zodResolver(companySignalSchema),
    mode: "onChange",
    defaultValues: {
      fund: Fund.Pipeline,
      note: "",
      sourceName: "",
      sourceType: "",
      alreadyMet: false,
      introAvailable: false,
      highPriority: false,
      ballInOurControl: false,
      activelyRaising: false,
      isDeepTech: false,
    },
  });
};
