import React, { useState } from "react";

import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router";

import { Button, Icon } from "@/app/components";
import { BreadCrumbs } from "@/app/components/navbar";
import { ModalV2 } from "@/app/components/v2/modal-v2/modal-v2.tsx";
import { Routes } from "@/app/constants";
import { ConfirmModal } from "@/app/screens/modal";
import { useDeclineAddCompanyEmailMutation, useGetCompanyEmailQuery } from "@/app/service/company-email.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

import { AddCompanyEmailDetails } from "./add-company-email-details.tsx";
import { AddOpportunitiesViaEmail } from "./add-opportunities-via-email.tsx";

export const AddCompaniesViaEmailView = observer(function AddCompaniesViaEmailView({
  navigationPath,
}: {
  navigationPath?: { url: string; label: string };
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, refetch: refetchEmail } = useGetCompanyEmailQuery({
    fetchPolicy: "cache-and-network",
    variables: { id },
    skip: !id,
  });

  const [detailsOpen, setDetailsOpen] = useState(false);

  const opportunityEmail = data?.nzrNotification?.nzrOpportunityEmail;

  const [decline, { loading: decliningEmail }] = useDeclineAddCompanyEmailMutation();

  const openEmailDetails = () => {
    setDetailsOpen(true);
  };

  const handleDecline = () => {
    const notificationId = data?.nzrNotification?.id;

    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure you want to decline this email?",
        subTitle: "By confirming, you will skip all opportunities from this email",
        handleConfirm: () => {
          if (notificationId) {
            decline({
              variables: {
                input: {
                  notificationId,
                },
              },
            }).then(() => {
              refetchEmail();
            });
          }
        },
      },
    });
  };

  const navigateBack = () => {
    const navigatePath = navigationPath?.url ?? Routes.home;
    navigate(navigatePath);
  };

  const emailId = opportunityEmail?.id;

  const hasErrored =
    !!data?.nzrNotification?.nzrOpportunityEmail?.metadata?.error ||
    (data?.nzrNotification?.nzrOpportunityEmail?.status === "FAILED" && !data?.nzrNotification.payload?.email?.no_opps);
  const isCompleted = data?.nzrNotification?.status === "COMPLETED";
  const isDeclined = data?.nzrNotification?.status === "DECLINED";
  const message = isCompleted
    ? "This email has been completed"
    : isDeclined
      ? "This email has been declined"
      : data?.nzrNotification?.payload?.title;

  if (!id || !emailId) {
    return;
  }

  // can't decline whole email if some company was completed
  const canDecline =
    !isCompleted &&
    !isDeclined &&
    !data?.nzrNotification?.nzrOpportunityEmail?.nzrOpportunityEmailCandidatesByEmailId.nodes.filter(
      (candidate) => candidate.status === "COMPLETED",
    ).length;

  return (
    <div className="flex h-screen w-full flex-col items-center overflow-auto bg-background">
      <div className="sticky top-0 z-10 flex w-full flex-row items-center justify-between bg-[rgba(255,255,255,0.1)] p-2 backdrop-blur-md sm:px-4 lg:px-6 lg:py-4 2xl:px-8">
        <BreadCrumbs title={navigationPath?.label ?? "Opportunities via email"} action={navigateBack} />
        <div className="flex gap-2">
          <Button text="Show original email" size="sm" onClick={openEmailDetails} />
          {canDecline && (
            <Button className="ml-auto" size="sm" loading={decliningEmail} text={"Decline"} onClick={handleDecline} />
          )}
        </div>
      </div>

      <div className="w-full space-y-8 px-2 pb-2 sm:px-4 lg:px-6 lg:pb-4 2xl:px-8">
        <div className="flex w-full items-center gap-2 rounded-xs border bg-white p-4 text-gray-800">
          {hasErrored ? (
            <>
              <Icon type="Shield Alert" className="size-5 text-red-400" />
              {data?.nzrNotification?.nzrOpportunityEmail?.metadata?.error ??
                "It seems that the email provided does not contain any company recommendations or relevant information to extract."}
            </>
          ) : (
            <>
              <Icon type="InfoOutline" className="size-5" />
              {message}
            </>
          )}
        </div>

        <AddOpportunitiesViaEmail data={data} />
      </div>
      <ModalV2 title="Email details" isOpen={detailsOpen} onClose={() => setDetailsOpen(false)}>
        <AddCompanyEmailDetails email={data?.nzrNotification?.nzrOpportunityEmail}></AddCompanyEmailDetails>
      </ModalV2>
    </div>
  );
});
